.underlineClass {
    &::after {
        padding: 32px;
    }
}
.textField {
    input,
    textarea {
        padding: 10.5px 0 10px 8px;
        color: var(--text) !important;
    }
}
.MuiSelect {
    padding-left: 8px !important;
    color: var(--text) !important;
}
