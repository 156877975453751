#ScheduledPage {
    width: 100%;
    padding: 16px;
    // background-color: yellow;
    .container {
        .list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 8px;
        }
    }
}
