.container {
    display: flex;
    gap: 24px;
    padding: 24px 0;

    & + & {
        border-top: 1px dashed #E0E0E0;
    }

    .image {
        width: 80px;
        height: 80px;
        border-radius: 4px;
    }

    .imageFallback {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        background-color: #dfdcdc;
        display: flex;
        align-items: center;
        justify-content: center;
    
        .icon {
            color: #fbfbfb;
            font-size: 40px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;

        .name {
            color: var(--primaryDark);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
        }

        .description {
            all: unset;
            color: var(--text);
            font-family: Roboto;
            font-size: 12px;
            line-height: 150%;
        }

        .price {
            color: var(--text);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            display: block;
        }

        .compositions {
            margin: 4px 0 16px;

            .composition {
                margin-top: 8px;

                b {
                    color: var(--primary);
                    font-weight: 700;
                    text-transform: capitalize;
                    margin-bottom: 4px;
                    display: block;
                }

                .compositionNameContent {
                    span {
                        color: var(--text);
                        text-transform: capitalize;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .totalContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        gap: 8px;
        align-items: flex-end;

        .quantityContainer {
            border-radius: 8px;
            border: 1px solid #BDBDBD;
            background-color: white;
            padding: 0 16px;
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 32px;
            margin-left: auto;

            .iconButton {
                all: unset;
                cursor: pointer;
                padding-top: 2px;
                color: var(--primary);

                span {
                    font-size: 12px;
                }

                &:disabled {
                    color: #BDBDBD;
                    cursor: default;
                }
            }

            .quantityText {
                color: #888;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
            }
        }

        .total {
            color: #A2A2A2;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
        }
    }
}


@media (max-width: 750px) {
    .image, .imageFallback {
        width: 50px !important;
        height: 50px !important;

        .icon {
            font-size: 20px !important;
        }

    }
    
    .name {
        font-size: 16px !important;
    }
    
    .price {
        font-size: 16px !important;
    }

    .total {
        font-size: 14px !important;
    }

    .quantityContainer {
        width: 80px !important;
        padding: 0 4px !important;
        gap: 6px !important;
        height: 32px !important;

        .quantityText {
            font-size: 16px !important;
        }
    }
}