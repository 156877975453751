#containerDetailsCustomer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 12px 10px;
  background: #e6f1ff;

  .checkAll {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #5f5f5f;
  }

  .customerContainer {
    background: #ffffff;
    border-radius: 13.8463px;
    overflow-x: auto;

    .customer {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 15px;
    }

    .customerList {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: auto;
    }
    .customersDetailsOpen {
      @extend .customerList;
      height: 0;
    }
    .customersDetailsClose {
      @extend .customerList;
    }

    .containerDetailsCustomers {
      padding: 0 16px 26px;

      .headerTitle {
        display: flex;
        padding: 20px 16px 10px;
        border-top: 0.5px solid #bdbdbd;
        flex-wrap: wrap;
        .title {
          flex: 2;
          text-align: center;
        }
        p {
          color: #001537;
          font-weight: bold;
          font-size: 1rem;
        }

        .titlePeople {
          @extend .title;
        }
        .titleDocument {
          @extend .title;
        }
        .titleAge {
          @extend .title;
        }
      }
      .containerCustomersList {
        border: none;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 16px;
        // box-shadow: 2px 2px 10px -2px #0005;
        border: dashed 1px #001537;;

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1;
        .text {
          font-size: 0.875rem;
          text-align: center;
          flex: 2;
          color: var(--text);
          min-width: 200px;
        }
        .nameParticipant {
          @extend .text;
          font-weight: 550;
        }
        .document {
          @extend .text;
        }
        .birthDate {
          @extend .text;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .qrCode{
          display: flex;
          flex:1;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .textCustomer {
      font-size: 0.875rem;
      color: var(--text);
      display: flex;
      flex: 1;
      align-items: center;
    }

    .name {
      display: flex;
      align-items: center;
      @extend .textCustomer;
      min-width: 200px;
      flex: 2;
      // p {
      //   white-space: nowrap !important;
      //   overflow: hidden !important;
      //   text-overflow: ellipsis !important;
      // }
      margin-right: 5px;
    }
    .birthDate {
      @extend .textCustomer;
      margin-right: 5px;
    }
    .tour {
      @extend .textCustomer;
      min-width: 200px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      margin-right: 5px;
    }
    .netValue {
      @extend .textCustomer;
      min-width: 100px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      margin-right: 5px;
    }
    .service {
      @extend .textCustomer;
      min-width: 200px;
      // white-space: nowrap !important;
      // overflow: hidden !important;
      // text-overflow: ellipsis !important;
      margin-right: 5px;
      flex:1;
    }
    .participantsQuantity {
      @extend .textCustomer;
      display: flex;
      flex:1;
      margin-right: 5px;
      min-width: 100px;
      // background-color: aqua
    }
    .document {
      @extend .textCustomer;
      margin-right: 5px;
    }
    .currentState {
      @extend .textCustomer;
      margin-right: 5px;
    }
    .dateTour {
      @extend .textCustomer;
      display: flex;
      min-width: 200px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      div{
        display: flex;
        flex:1;
        justify-content: flex-start;
        text-align: left;
        // background-color: aqua;
        
      }
    }
    .formPayment {
      @extend .textCustomer;
      margin-right: 5px;
    }
    .buttonOptions {
      display: flex;
      flex: 0.5;
      justify-content: flex-end;
      align-items: center;
      @extend .textCustomer;
      margin-right: 5px;
    }
  }
}
