.container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .title {
        color: var(--text);
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        display: block;
        margin-top: 16px;
    }

    .details {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        gap: 12px;
        width: 100%;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px dashed #E0E0E0;
    }

    .deliveryFee {
        color: var(--text);
        font-size: 16px;
    }

    .itemResume {
        display: flex;
        align-items: center;
        gap: 16px;

        .infoFeeButton {
            all: unset;
            cursor: pointer;
            position: relative;
            top: 2px;

            .infoFeeIcon {
                color: var(--text);
                font-size: 20px;
            }
        }

        .quantityContainer {
            width: 30px;
            height: 30px;
            border-radius: 8px;
            border: 1px solid #BDBDBD;
            display: flex;
            align-items: center;
            justify-content: center;

            > span {
                color: var(--text);
                font-size: 16px;
            }

            .quantityText {
                color: var(--text);
                font-family: Ubuntu;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                position: relative;
                top: -1px;
            }
        }

        .productName {
            color: var(--primaryDark);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            text-align: left;
        }

        .productPrice {
            color: var(--text);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            margin-left: auto;
        }
    }
}

.modalContent {
    min-width: 400px;
}

.modalFeeTitle {
    color: var(--text);
    font-family: Roboto;
    font-size: 26px;
    font-weight: 700;
    line-height: 150%;
}

.modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modalFeeDescription {
    color: var(--text);
    font-family: Roboto;
    font-size: 16px;
    line-height: 150%;
    position: relative;
    top: -1px;
}

.closeButton {
    all: unset;
    cursor: pointer;
}

.modalRow {
    display: flex;
    gap: 8px;
}

.modalConfirButton {
    all: unset;
    cursor: pointer;
    background-color: var(--secondary);
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 16px;

    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
}

@media (max-width: 740px) {
    .productName {
        font-size: 16px !important;
        max-width: 212px !important;
        overflow: hidden !important;
    }

    .productPrice {
        font-size: 16px !important;
    }

    .quantityContainer {
        width: 30px !important;
        height: 30px !important;

        .quantityText {
            font-size: 16px !important;
        }
    }
}