@import 'styles/main.scss';

#HeaderLayout {
    display: flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
    height: 70px;

    button {
        color: #001537 !important;
    }

    .buttonHelp {
        color: #001537 !important;

    }

    .container {
        display: flex;
        padding: 16px;
        flex: 1;
        flex-direction: column;
        align-items: center;

    }
}