#Payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 60vh;
  width: 495px;
  gap: 28px;

  >div {
    height: 100%;
    padding: 0;
  }
}
