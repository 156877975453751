#privateHeader {
    position: relative;
    top: 0;
    width: 100vw;
    z-index: 1;
}

#privateHeader .icon {
    color: #fff;
}

.iconButtonContainer {
    display: flex;
    flex: 1;
}

#privateHeader .backButton{
    position: fixed;
    /* background-color: #0005; */
    color: #fff;
    top: 3px;
    left: 0px;
    z-index: 1;
}