@import "styles/main.scss";
#ModalContainer {
    display: flex;
    flex-direction: column;

    min-width: 275px;
    min-height: 300px;

    padding: 1rem;
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
            color: var(--secondary-dark);
        }
        svg {
            color: var(--secondary-dark);
            width: 30px;
            height: 30px;
        }
    }
    .modalContent {
        padding: 0.2rem;
    }
    .modalActions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
            flex: 1;
            text-transform: capitalize;
        }
    }
}
.paper {
    border-radius: 15px !important;
}
