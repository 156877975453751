.title {
    color: var(--text);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    margin-top: 32px;
}

.modalContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContent {
    background-color: var(--secondaryBackground);
    max-width: 600px;
    margin: 0 auto;
    overflow: auto;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        all: unset;
        color: var(--text);
        font-size: 20px;
        font-weight: 700;
    }

    .whereToPayLabel {
        color: var(--text);
        display: block;
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 150%;
        margin: 16px 0 4px;
    }
}

.changeTitle {
    color: var(--text);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    margin-top: 24px;
}

.changeDescription {
    color: var(--text);
    font-family: Roboto;
    font-size: 14px;
    line-height: 150%;
    margin-top: 8px;
    margin-bottom: 4px;
}

.emptyPaymentModalMessage {
    font-family: Roboto;
    margin-bottom: 1.5rem;
    text-align: center;
}

.closePaymentModalButton {
    background: var(--primary);
    width: 100%;
}

@media (max-width: 740px) {
    .title {
        font-size: 22px !important;
    }

    .changeTitle {
        font-size: 22px !important;
    }
}