#ButtonPicker {
}
.container {
    margin: 16px 0;
    padding: 8px 8px;
    // border-radius: 4px;
    animation-name: inAnimation;
    animation-duration: 0.5s;
    align-items: center;
    border: 0.5px solid #e5e5e5;
    display: flex;
    justify-content: right;
    // color: var(--primary);
    // box-shadow: 0 5px 20px -10px #0005;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
}
.containerUnselected {
    @extend .container;
    // color: var(--primary);
    // border: var(--primary) 1px dashed;
    // color: var(--primary);
}
.containerSelected {
    @extend .container;
    background-color: #fff;
    // box-shadow: 2px 2px 10px 0px #0005;
    // border: var(--primary) 1px solid;
    animation-name: onSelect;
    // color: var(--primary);
    animation-duration: 0.8s;
}
.content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .botaoLabel {
        color: #333333;
        font-size: x-small;
        display: flex;
        justify-content: left;
        align-items: center;
        flex: 1;
    }
}

.botaoContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    // background-color: aqua;
}
.iconContainer {
    margin-right: 16px;
}
.icon {
    display: flex;
    justify-content: space-between;

    color: #333333 !important;
}

.acordion {
    background-color: "#fff";
    overflow: hidden;
}
.acordionOpen {
    transition: all 0.5s ease-in-out;
    @extend .acordion;
}
.acordionClose {
    transition: all 0.3s ease-in-out;
    @extend .acordion;
    max-height: 0;
}

@keyframes inAnimation {
    from {
        transform: translatex(-100vw);
    }
    to {
        transform: translatex(0vw);
    }
}
@keyframes onSelect {
    0% {
        transform: scale(1);
        color: #e5e5e5;
    }
    20% {
        transform: scale(1);
        color: #e5e5e5;
    }
    60% {
        transform: scale(1.1);
        background-color: #e5e5e5;
        color: #fff;
    }
    100% {
        transform: scale(1);
        color: #e5e5e5 !important;
    }
}
