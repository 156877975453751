#orderInProgressItem {
    position: relative;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    margin: 16px;
    overflow: hidden;
    box-shadow: 2px 2px 10px -5px #000!important;
}

#orderInProgressItem .buttonBase {
    padding: 8px!important;
}

#orderInProgressItem .content {
    position: "relative";
    display: flex!important;
    flex: 1;
    flex-direction: column;
}

#orderInProgressItem .storeName {
    color: var(--primary  );
    font-size: large;
}

#orderInProgressItem .createAt {}

#orderInProgressItem .total {
    display: flex;
    justify-content: center;
    color: #1a5;
    font-size: large;
}

#orderInProgressItem .status {
    display: flex;
    flex: 0;
    padding: 4px;
    justify-content: center;
    text-align: center;
    background-color: #1ac;
    border-radius: 4px;
    color: #fff;
    font-size: large;
    justify-content: center;
    align-items: center;
    white-space: pre;
}

#orderInProgressItem .pedido {
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 16px;
}

#orderInProgressItem .qrCodeIcon {
    position: absolute;
    top: 8px;
    right: 8px;
}

#orderInProgressItem .actionsBuntons {
    margin-top: 8px;
    display: flex;
    flex: 1;
}

#orderInProgressItem .button {
    display: flex;
    flex: 1;
}

#qrCodeContainer {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#qrCodeContainer .content {}

#qrCodeContainer .storeName {
    color: var(--primary  );
    font-size: x-large;
}

#qrCodeContainer .createAt {}

#qrCodeContainer .total {
    color: #1a5;
    font-size: xx-large;
}

#qrCodeContainer .pedido {}