@import "styles/main.scss";
#ConquistasProfile {
    margin-bottom: 24px;
    h2 {
        margin: 16px;
    }
    .container {
        display: flex;
        justify-content: space-around;
        img{
            height:40px;
        }
    }
}
