#header {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

#header .container {
    position: relative;
    display: flex;
    height: 50px;
    flex: 1;
    background-color: var(--primaryDark);
    color: #fff;
    align-items: center;
}

#header .container .start {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: flex-end;
    flex: 0
}

#header .container .center {
    position: absolute;
    display: flex;
    left: 50px;
    right: 50px;
    justify-content: center;
}

#header .container .end {
    position: absolute;
    right: 0;
    display: flex;
    flex: 0
}