.addCard {
    input {
        color: var(--text);
    }

    > div:first-of-type {
        > h4 {
            all: unset;
            color: var(--text);
            font-size: 20px;
            font-weight: 700;
            display: flex;
            margin-bottom: 16px;
            align-items: center;
            justify-content: space-between;
            > button {
              color: var(--primaryDark);
              width: 40px;
              height: 40px;
            }
        }
    }
}

.submitButton {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    
    color: white;
    font-size: 18px;
    line-height: 150%;
    font-weight: 700;

    &:disabled {
        background-color: #BDBDBD;
        border-color: #BDBDBD;
    }
}

.cancelButton {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    background-color: white;
    border: 1px solid var(--secondary);
    
    color: var(--secondary);
    font-size: 18px;
    line-height: 150%;
    font-weight: 700;
}