#Dependents {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  position: relative;
  background: #fff;

  > div:first-of-type {
    //PrivateHeader
    min-height: 50px;
  }

  .container {
    max-width: 900px;
    width: 80%;
    padding: 30px 0;

    > div:first-of-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 26px;
      width: 100%;

      > span {
        color: var(--Cinza-0, #333);
        font-family: Roboto;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 45px */
      }

      > button {
        display: flex;
        height: 40px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        color: var(--Neutral-White, #fff);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        text-transform: unset;

        border-radius: 8px;
        background: var(--Primary-Primary, #32008e);

        > span:first-of-type {
          > svg {
            margin-left: 8px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    > div:last-of-type {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      gap: 16px;
    }

    .emptyList {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;
      align-self: stretch;

      > p {
        color: var(--Neutral-Gray-800, #5f5f5f);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
  }
}

.listItem {
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Neutral-Gray-500, #e0e0e0);

  > div:first-of-type {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;

    > span {
      color: var(--Neutral-Gray-900, #333);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 30px */
    }

    > div:first-of-type {
      > button {
        padding: 6px;

        > span:first-of-type {
          > svg {
            width: 24px;
            height: 24px;
            path {
              fill: var(--Primary-Primary-Dark, #001537);
            }
          }
        }
      }
    }
  }
  > p {
    margin: 0;
    color: var(--Neutral-Gray-800, #5f5f5f);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  > h5 {
    margin: 0;
    color: var(--Feedback-Colors-Positive, #399c54);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }
}
.newDependentModal {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  > div {
    border-radius: 16px !important;
    background-color: #fff;
  }
}
.tagModal {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  > div {
    border-radius: 16px !important;
    background-color: #fff;
  }
}
.modal {
  width: 100%;
  padding: 0 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  width: 60%;
  padding: 24px !important;
  min-height: auto !important;
  border-radius: 16px !important;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;

    > span {
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }

    svg {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      color: var(--Primary-Primary-Dark, #001537);
    }
  }

  > p {
    color: var(--Neutral-Gray-900, #333);
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 45px */
  }

  > button:first-of-type {
    display: flex;
    margin-top: 8px;
    height: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    background: var(--Primary-Primary-Light, #955cff);

    > span:first-of-type {
      color: var(--Neutral-White, #fff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  > button:first-of-type:hover {
    background-color: lighten(#955cff, 15%);
  }

  > button:last-of-type {
    display: flex;
    margin-top: 16px;
    height: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border: 2px solid var(--Primary-Primary-Light, #955cff);

    > span:first-of-type {
      color: var(--Primary-Primary-Light, #955cff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  > button:last-of-type:hover {
    background-color: #f3edff;
  }
}
