#FaqCard {

    .containerCategory {
        .containerQuestion {
            padding: 0 16px;
            border: 1px solid #E0E0E0;
            box-sizing: border-box;
            border-radius: 16px;

            .containerDescription {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .description {
                    font-weight: 500;
                    color: #333333;
                }

                svg {
                    color: #001537;

                }
            }

            .answers {

                margin-top: 0;
                font-size: 0.875rem;
                color: #5f5f5f;
                text-align: justify !important;

            }

            .faqAnswerCard {
                display: flex;
                flex-direction: column;
                position: relative;
                overflow: auto;
            }

            .showCard {
                @extend .faqAnswerCard;
            }

            .hideCard {
                @extend .faqAnswerCard;
                height: 0;
            }

        }
    }

}