#Ticket {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  > button {
    width: 340px;
    align-self: center;
    margin-top: 8px;
    color: var(--Neutral-White, #fff);
    background-color: var(--primary, #fff);
    text-transform: capitalize;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .title {
    align-self: stretch;
    color: var(--Primary-Primary-Dark, #001537);
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 12px;

  }

  .description {
    padding: 0 4px;
    display: flex;
    height: 64px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;

    color: var(--Neutral-Gray-800, #5f5f5f);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;

    >b {
      display: contents;
    }
  }

  .ticketContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 152px;

    .barcode {
      >svg {
        width: 100%;
      }
    }

    >div:first-of-type {
      margin-top: 10px;
      display: flex;
      align-items: center;
      grid-gap: 10px;
      justify-content: space-between;
      gap: 10px;
    }

    .divisor {
      min-height: 12px;
      border-left: 1px solid #ddd;
    }
  }

  .ticketFooter {
    overflow: hidden;
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    >p {
      display: block;
      padding: 10px;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    >div {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      >div:first-of-type {
        cursor: pointer;
        display: flex;
        width: 340px;
        height: 40px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--Primary-Primary, #32008e);

        color: var(--Neutral-White, #fff);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        /* 24px */
      }

      >div:last-of-type {
        cursor: pointer;
        display: flex;
        width: 340px;
        height: 40px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 16px;

        border-radius: 8px;
        border: 2px solid var(--Primary-Primary, #32008e);
      }
    }

    .disabled {
      cursor: not-allowed !important;
      opacity: 0.6;
    }
  }
}