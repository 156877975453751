#Carousel {

  position: relative;
  border-radius: 16px;

  .nextArrow {
    position: absolute;
    top: 50%;
    right: 0px;
    width: 45px;
    height: 45px;
    background: transparent;
    border: 0 none;
    margin-top: -22.5px;
    text-align: center;
    color: #FFF;
    z-index: 5;

    svg {
      font-size: small;
    }
  }

  .Modern-Slider .NextArrow:before {
    content: '\f105';
  }

  .prevArrow {
    position: absolute;
    top: 50%;
    left: 0px;
    width: 45px;
    height: 45px;
    background: transparent;
    border: 0 none;
    margin-top: -22.5px;
    text-align: center;
    color: #FFF;
    z-index: 5;

    svg {
      font-size: small;
    }
  }



  img {
    border-radius: 16px;
    height: 600px;
    margin-bottom: 10px;

    object-position: center;
    object-fit: cover;
  }
}

@media screen and (max-width: 600px) {
  #Carousel {
    margin: 0 16px;

    img {
      height: 160px;
    }
  }

}


@media screen and (max-width: 1919px) and (min-width: 600px) {
  #Carousel {

    img {
      height: 350px !important;
    }

  }


}