#SecurityCodeInput {}

#SecurityCodeInput .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 250px; */
    width: 100vw;
    max-width: 600px;
    margin: auto;
}

#SecurityCodeInput .content {
    display: flex;
    margin: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#SecurityCodeInput .description {
    text-align: center;
}

#SecurityCodeInput .buttonContainer {
    margin: 16px;
}

#SecurityCodeInput .inputContainer {
    max-width: 200px;
}

#SecurityCodeInput .textField {}