#PickerDate {
    padding: 0px;
    .botao {
    }
    .botaoContainer {
        display: flex;
        .botaoLabel {
            display: flex;
            justify-content: left;
            align-items: center;
            flex: 1;
        }
    }
    .icon {
        color: var(--primary) !important;
    }
    .calendar {
        background-color: "#fff";
        overflow: hidden;
    }
    .calendarOpen {
        transition: all 0.5s ease-in-out;
        @extend .calendar;
        max-height: 500px;
    }
    .calendarClose {
        transition: all 0.3s ease-in-out;
        @extend .calendar;
        max-height: 0;
        background-color: aqua;
    }
}
#drawerDate {
    h2 {
        color: #333333;
        text-align: center;
    }
    .calendar {
        background-color: "#fff";
        padding: 16px;
        overflow: hidden;
    }
}

.busyDay {
    // color: gray!important;
    // background-color: #d552!important;
    // border-radius: 4px;
    // border: #fff 1px solid!important;
}
.availabeDay {
    color: green!important;
    background-color: #0a52!important;
    border-radius: 4px;
    border: #fff 1px solid!important;
    margin: 2px;
}
.react-calendar {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background: white;
    // border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.react-calendar--doubleView {
    // width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    margin-bottom: 1em;
    display: flex;
}
.react-calendar__navigation button {
    min-width: 30px;
    background: #FFF;
    color: #333333;
    font-size: 1.125rem;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin: 4px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    box-shadow: 2px 2px 10px 0px #0005;
    transition: all 0.2s;
}
.react-calendar__navigation button[disabled] {
    opacity: 0.4;
}
.react-calendar__month-view__weekdays abbr[title] {
    color: #333333;
    text-decoration: none;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day {
    border-radius: 8px;
    padding: 16px;
    padding: 8px;
    margin: 0px;
}
.react-calendar__month-view__days__day--weekend {
    color: blue;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
    opacity: 0.5;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}
.react-calendar__tile:disabled {
    color: #444;
    opacity: 0.5;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    // background: #ffff76;
    border: 2px solid #333333 !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #0005;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    background: #333333;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--primary);
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
