#StoreItem{
    .container{
        position: relative;
        height: 200px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        background-color: var(--primary);
        .descriptionContainer{
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 10px;
            // height: 100px;
            color: #fff;
            background-color: #0009;
            display: flex;
            align-items: center;
            padding: 8px;
            .description{
                // position: absolute;
                // margin-left: 116px;
                top: 8px;
                // background-color: aqua;
                display: flex;
            }
            .logo{
                position: absolute;
                left: 16px;
                bottom: 16px;
                width: 100px;
                height: 100px;
                .image{
                    border-radius: 4px;
                    object-fit: cover;
                }
            }
        }
    }

    .simpleContainer{
        position: relative;
        height: 120px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom: 1px solid #0002;
        .descriptionContainer{
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 16px;
            height: 100px;
            // color: #fff;
            display: flex;
            align-items: center;
            padding: 8px;
            .description{
                position: absolute;
                margin-left: 116px;
                top: 8px;
                // background-color: aqua;
                display: flex;
            }
            .logo{
                position: absolute;
               border-radius: 8px;
                width: 100px;
                height: 100px;
                .image{
                    border-radius: 4px;
                    object-fit: cover;
                }
            }
        }
    }
}