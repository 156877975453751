#orderHistoryPage{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--background);
}

.title-orderHistoryPage {
    font-size: large;
    color: var(--primary  );
    margin: 0 16px;
    border-bottom: 1px solid var(--primary  );
    margin-bottom: 16px;
}