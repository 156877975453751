.container-card-item {
    border: 1px solid var(--primary  )!important;
    box-shadow: 2px 2px 5px #0005!important;
    width: 100%!important;
    margin: 8px 0!important;
    border-radius: 4px!important;
    display: flex!important;
    flex: 1!important;
    font-family: roboto !important;
}

.icon-grid-card-item {
    display: flex;
    flex: 0;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
}

.icon-card-item {
    color: var(--primary  );
}

.content-card-item {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
}

.text-content-card-item {
    overflow: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 8px;
}

.top-content-card-item {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: flex;
    align-items: center;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    color: var(--text);
    font-size: 14px;
    line-height: 150%;
}

.bottom-content-card-item {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: flex;
    align-items: center;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    text-align: right;
    opacity: 0.5;
}

.brand-image-content-card-item {
    width: 50;
    height: 50;
    display: flex;
    align-items: center;
    object-fit: contain;
    /* background: #654 */
}

.actions-card-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.autenticatedIcon {
    /* position: absolute;
    top: -10px;
    left: -10px */
    display: flex;
    justify-content: center;
    align-items: center;
}