#PaymentTypeSelect {
  display: flex;

  .input {
    width: 100%;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Primary-Primary-Light, #955cff);

    > span:first-of-type > div {
      color: var(--Cinza-0, #333);
      text-transform: none;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 30px */
    }
  }
}

.modalCardContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px !important;

  > div:nth-child(3) {
    padding: 0;
    > div {
      padding: 24px !important;
      border-radius: 16px !important;

      > form {
        > div:first-of-type {
          display: none;
        }

        > div:last-of-type > div:last-of-type {
          margin: 0px !important;
          display: flex !important;
          justify-content: space-between !important;
          align-items: center !important;
          width: 100% !important;
          padding: 8px !important;
          gap: 8px !important;

          > div {
            all: unset;
            display: flex !important;
            flex: 1;
          }
        }
      }
    }
  }
}

.modalContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background-color: var(--secondaryBackground);
  width: 550px;
  min-height: 500px;
  margin: 0 auto;
  overflow: auto;
  border-radius: 8px;
  padding: 24px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-of-type {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > h4 {
      all: unset;
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 45px */
    }
    > button {
      padding: 6px;
      svg {
        width: 40px;
        height: 40px;
        color: var(--SecondaryDark, #001537);
      }
    }
  }

  > div:last-of-type {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;

    > button {
      display: flex;
      min-height: 65px !important;
      max-height: 65px !important;
      padding: 0px !important;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px !important;
      border: 1px solid var(--Primary-Primary-Light, #955cff) !important;

      > span:first-of-type {
        color: var(--Primary-Primary-Light, #955cff);
        text-align: center;
        text-transform: none;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */

        > svg {
          color: var(--Primary-Primary-Light, #955cff);
          width: 24px;
          height: 24px;
          margin-left: 16px;
        }
      }
    }

    > button:first-of-type {
      margin-bottom: 8px;
      border: 2px solid var(--Primary-Primary-Light, #955cff) !important;
    }

    > button:last-of-type {
      margin-top: 8px;
      display: flex;
      padding: 16px;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      align-self: stretch;

      border-radius: 8px;
      border: 1px solid var(--Primary-Primary-Light, #955cff);

      > span:first-of-type {
        color: var(--Neutral-Gray-800, #5f5f5f);
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */

        > svg {
          margin: 0 28px 0 16px !important;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
