#Recharge {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  position: relative;
  background: #fff;

  > div:first-of-type {
    //PrivateHeader
    min-height: 50px;
  }

  .container {
    width: 80%;
    max-width: 900px;
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h1 {
      width: 100%;
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
      margin-top: 30px;
    }

    > div:first-of-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      width: 100%;
      > p {
        margin: 0;
        color: var(--Neutral-Gray-700, #a2a2a2);
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
      }
      > div:first-of-type {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        > div > input {
          text-align: center;
          font-family: Roboto;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 48px */
        }
      }
    }
    > div:last-of-type {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      > h1 {
        margin: 0;
        color: var(--Cinza-0, #333);
        font-family: Roboto;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 45px */
      }

      > div:first-of-type {
        width: 80%;
        min-width: 300px;
        max-width: 550px;
      }

      > button {
        margin-top: 40px;
        display: flex;
        height: 55px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 8px;
        background: var(--Primary-Primary-Light, #955cff);

        color: var(--Neutral-White, #fff);
        text-transform: unset;
        text-align: center;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
      }
    }
  }
}
