#PixPage {
    position: relative;
    // background-color: aqua;
    .container {
        padding: 16px;
        display: flex;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 16px;
        img {
            width: 90%;
            max-width: 400px;
        }

        div {
            font-weight: bold;
            width: 200px;
            padding: 32px;
        }
    }
    h2{
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary);
        padding: 16px;
    }
    .closeButton {
        position: absolute;
        right: 4px;
        top: 4px;
    }
}
