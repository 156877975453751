#SimpleHeader {
    .container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: var(--background);
        box-shadow: 0 2px 5px -3px #0005;
        z-index: 10;
        transition: all 0.2s ease-in-out;
        padding: 2px;
        
        .title {
            display: flex;
            padding-top: 8px;
            color: var(--primary);
            justify-content: center;
            font-weight: 700;
        }
    }
    .backButton {
        position: fixed;
        // background-color: #0005;
        color: #fff;
        top: 8px;
        left: 0px;
        z-index: 1;
    }
    .containerOpen {
        @extend .container;
        transform: translateY(0);
        opacity: 1;
    }

    .containerClose {
        @extend .container;
        transform: translateY(-140px);
        opacity: 0;
    }

    .tabWrapper {
        display: flex;
        justify-content: center;
    }
}
