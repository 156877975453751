.deliveryAddressContainer {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.deliveryAddressButon {
    all: unset;
    cursor: pointer;
    border-radius: 11.491px;
    border: 2px solid var(--primary);
    background: var(--primary);
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 16px;

    span.buttonText {
        color: #FFF;
        font-family: Roboto;
        font-size: 18px;
        letter-spacing: 0.215px;
        font-weight: 500;
    }

    .buttonIcon {
        font-size: 20px;
        color: white;
    }

    &:not(.selected) {
        background-color: #fff;

        span.buttonText {
            color: var(--primary);
        }

        .buttonIcon {
            color: var(--primary);
        }
    }
}

.contentDeliveryDescription {   

    border-radius: 4px;
    border: 1px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px;
    span {
        font-size: 20px;
    }

}

.title {
    color: var(--text);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    display: block;
    margin-bottom: 8px;
}

.modalContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addressModalContainer {
    background-color: var(--secondaryBackground);
    max-width: 600px;
    margin: 0 auto;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        all: unset;
        color: var(--text);
        font-size: 20px;
        font-weight: 700;
    }
}

.modalSubmitButton {
    all: unset;
    cursor: pointer;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--primary);

    .icon {
        color: white;
        font-size: 20px;
    }

    .text {
        color: white;
        font-weight: 500;
        font-family: Roboto;
    }
}

.deliveryCatchMessage {
    font-family: Roboto;
    font-size: 20px;
    margin: 8px;
    text-align: center;
    width: 100%;
}

@media (max-width: 740px) {
    .title {
        font-size: 22px !important;
    }
}