#statusItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}

#statusItem .stateIconStatusItem {
    color: #fff;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}