#NewPasswordForm {
  padding: 30px;
  width: 60%;
  min-width: 400px;
  h4 {
    all: unset;
    color: var(--text);
    font-size: 20px;
    font-weight: 700;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    > button {
      color: var(--primaryDark);
      width: 40px;
      height: 40px;
    }
  }

  > div:first-of-type {
    .input {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-self: stretch;
      height: 100px;

      > p {
        display: flex;
        align-items: center;
        margin: 0;
        color: var(--Neutral-Gray-900, #333);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;

        > span {
          margin: 0 4px;
          color: var(--Secondary-Secondary, #ed0029);
        }
        > svg {
          width: 18px;
          height: 18px;
        }
      }

      > div:first-of-type > div {
        display: flex;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--Neutral-Gray-600, #bdbdbd);

        color: var(--Neutral-Gray-700, #a2a2a2);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;

        > input {
          padding: 14px;
        }
      }
    }

    .rules {
      margin-top: 16px;
      margin-bottom: 32px;

      > h5 {
        margin: 4px 0;
        color: var(--Neutral-Gray-800, #5f5f5f);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }

      > p {
        margin: 8px 0;
        display: flex;
        align-items: center;
        color: var(--Neutral-Gray-800, #5f5f5f);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */

        > svg {
          margin-right: 8px;
        }
      }
    }
  }

  > button:first-of-type {
    margin-top: 24px;
    display: flex;
    height: 55px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--Primary-Primary-Light, #955cff);

    
    color: var(--Neutral-White, #fff);
    text-transform: capitalize;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }

  > button:last-of-type {
    margin-top: 24px;
    display: flex;
    height: 55px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 2px solid var(--Primary-Primary-Light, #955CFF);

    
    color: var(--Primary-Primary-Light, #955CFF);
    text-transform: capitalize;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }
}
