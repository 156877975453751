.container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--secondaryBackground);
    text-align: center;
}

.content {
    display: flex !important;
    flex-direction: column !important;
    padding: 80px 32px !important;
    background: var(--secondaryBackground) !important;
    max-width: -webkit-fill-available !important;
    margin: 75px !important;
    border-radius: 16px !important;
    align-items: center !important;
}

@media (max-width: 600px) {
    .content {
        margin: 0 !important;
        height: 100vh;
        flex: 1;
        justify-content: center;
    }
}