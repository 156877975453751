#orderTracking {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

#orderTracking .title {
    color: var(--primary  );
    margin: 0 0 8px 0;
    font-size: large;
}

#orderTracking .content {}

#orderTracking .info {}

#orderTracking .timeline {
    border: 1px solid #ddd;
    border-radius: 16px;
    padding: 16px;
}

#orderTracking .infoLabel {
    font-size: small;
}

#orderTracking .pedidoItens {
    font-size: small;
    padding: 0 16px 16px 16px;
}

#orderTracking .trackinDeliveryButton {
    align-self: center!important;
    margin: auto!important
}