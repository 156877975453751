#Wallet {
  min-height: 100vh;
  position: relative;

  > div:first-of-type {
    //PrivateHeader
    min-height: 50px;
  }

  .container {
    margin: 30px 60px;
    padding: 32px;

    border-radius: 16px 16px 16px 16px;
    background: var(--Neutral-White, #fff);

    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;

      padding-bottom: 32px;
      border-bottom: 1px solid var(--Neutral-Gray-500, #e0e0e0);

      > div:first-of-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;

        > div:first-of-type {
          display: flex;
          align-items: center;
          color: var(--Neutral-Gray-800, #5f5f5f);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 35px;

          > span {
            color: var(--Primary-Primary-Light, #955cff);
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;

            > span {
              display: inline-flex;
            }
          }
        }

        > div:last-of-type {
          display: flex;
          align-items: center;
          gap: 4px;
          cursor: pointer;

          color: var(--Neutral-Gray-800, #5f5f5f);
          text-align: right;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;

          > svg {
            width: 24px;
            height: 24px;
            color: #5f5f5f;
          }
        }
      }

      > p {
        display: flex;
        align-items: center;
        margin: 4px 0;
        color: var(--Neutral-Gray-800, #5f5f5f);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;

        > b {
          > span {
            display: inline-flex;
          }
        }
      }
    }

    .actions {
      margin-top: 30px;
      > div:first-of-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        > p {
          margin: 4px 0;
          color: var(--Neutral-Gray-800, #5f5f5f);
          text-align: right;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 20.8px */
        }
        > span {
          color: var(--Feedback-Colors-Positive, #399c54);
          font-family: Roboto;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 45px; /* 41.6px */
        }
      }

      > div:last-of-type {
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        margin-top: 30px;

        > div {
          display: flex;
          width: 137px;
          padding: 16px 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;

          border-radius: 16px;
          border: 1px solid var(--Primary-Primary-Light, #955cff);
          background: var(--Primary-Primary-200, #f3edff);

          transition: background-color 0.3s ease;

          cursor: pointer;
        }

        > div:hover {
          background-color: lighten(#955cff, 15%);
        }
      }
    }
  }
}

.tagModal {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  > div {
    border-radius: 16px !important;
    background-color: #fff;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.disabled:hover {
  all: unset;
}
