#Header{
    .defaultBackground{
        position: relative;
        height: 200px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 50px;
    }
    .container{
        position: relative;
        height: 200px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 50px;
    }
    .backButton{
        position: fixed;
        background-color: #0005;
        color: #fff;
        top: 8px;
        left: 8px;
        z-index: 1;
    }
}

.headerContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0 16px;
    background-color: var(--background);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 16px 16px 24px;
    box-shadow: 0 10px 10px -10px rgba(0,0,0,0.2);

    .imgContainer {
        width: 120px;
        height: 120px;
        border-radius: 12px;
        box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.2);

        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .content {
        display: flex;
        flex-direction: column;

        h1 {
            color: var(--primaryDark);
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            line-height: 150%;
        }

        span {
            color: var(--text);
            font-family: Roboto;
            font-size: 14px;
            line-height: 150%;
        }
    }
}