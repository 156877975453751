#ProductList {
    .container {           
        margin: 0 16px;
        .section {
            .title {
                color: var(--primaryDark);
                border-bottom: 2px solid var(--primary);
                padding: 8px 8px 4px;
                margin-bottom: 10px;

                font-family: Roboto;
                font-size: 18px;
                font-weight: 700;
                line-height: 120%;
                text-transform: capitalize;
            }

            .list {
                display: grid;
                grid-template-columns:  repeat(auto-fill, minmax(100%, 1fr));
                column-gap: 16px;
                flex-direction: row;
                justify-content: center;
            }
        }

        .emptyListFallback {
            font-size: 1.5rem;
            opacity: 0.5;
            padding: 0 1rem 3rem;
            text-align: center;
        }
    }
}
