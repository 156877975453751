.container-resume {
    padding: 16px;
    /* box-shadow: 2px 2px 5px -2px #0005; */
    border-radius: 4px;
    /* border:1px solid var(--primary  ); */
    border: 1px dashed var(--primary  );
    margin: 8px 0;
}

.top-resume {
    color: var(--primary  );
    text-align: center;
    font-size: larger;
}

.content-resume {}

.item-resume {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;
    padding: 4px;
    font-size: small;
}

.quantity-item-resume {
    padding: 0 8px;
    color: var(--primary  );
    font-weight: 550;
}

.name-item-resume {
    display: flex;
    margin: 0 4px;
    flex: 1;
    opacity: 0.8;
    text-align: left;
}

.price-item-resume {
    text-align: right;
    padding: 0 8px;
    font-size: medium;
    /* color: #296; */
    /* font-weight: 400; */
}

.footer-resume {
    display: flex;
}

.label-total-resume {
    text-align: right;
    color: #3b8;
    font-size: large;
}

.value-total-resume {
    text-align: center;
    color: #3b8;
    font-size: large;
}