#ConfirmPasswordInput {}

#ConfirmPasswordInput .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 250px; */
    width: 100vw;
    max-width: 600px;
    margin: auto;
}

#ConfirmPasswordInput .content {
    display: flex;
    margin: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ConfirmPasswordInput .description {
    text-align: center;
}

#ConfirmPasswordInput .buttonContainer {
    margin: 16px;
}

#ConfirmPasswordInput .inputContainer {
    max-width: 200px;
}

#ConfirmPasswordInput .textField {}