#productDetails {
    display: flex;
    justify-content: space-between;
    font-size: small;
    margin: 4px;
}

#productDetails .container {
    display: flex;
    justify-content: space-between;
}

#productDetails .quantidade {
    margin-right: 4px;
}

#productDetails .name {}

#productDetails .total {
    margin-left: 4px;
    color: #1a5;
    font-size: small;
}