#private {
    .container {
        display: grid;
        margin-top: 60px;
        grid-template-columns: 255px minmax(250px, 1fr);
        flex: 1;
        height: calc(100vh - 60px);
        // background-color: aqua;
        .menu {
            border-right: var(--primary) 1px solid;      
        }
        .content {
            // background-color: violet;
            display: flex;
            flex: 1;
        }
    }
    @media (max-width: 1000px) {
        .container {
            grid-template-columns:  minmax(250px, 1fr);
        }
        .menu {
            display: none;
            border-right: transparent 5px solid;
            overflow: hidden;
        }
    }
}
