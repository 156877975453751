#PaymentResumePage {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px;
  max-width: 700px;
  margin: auto;

  h2 {
    font-size: 1.125rem;
    color: #001537;
    font-weight: 700;
  }

  h3 {
    color: #5f5f5f;
    font-size: 1.125rem;
    font-weight: 700;
  }

  .cartItemResume {
    //box-shadow: 2px 2px 7px -5px #0005;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  .resumeDown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h2 {
      font-size: 1.125rem;
    }

    svg {
      font-size: 2rem;
      color: #001537;
    }
  }

  .infoHour {
    background: #FFE29E;
    border-radius: 16px;
    margin: 0 0 16px;
    padding: 10px;
    p {
      color: #333333;
      margin: 0;
    }
  }

  .headerInfoResumePayment {

    .containerInfoResumePayment {

      >div {
        margin-bottom: 16px;
      }

      h4 {
        color: var(--primary);
        font-size: 0.9rem;
        font-weight: 600;
        margin: 5px 0 0;
      }

      p {
        color: #5f5f5f;
        margin: 0;
      }
    }
  }

  .footerInfoResumePayment {
    .total {
      margin: 16px 0;
      h3 {
        font-size: 1.125rem;
      }
    }

    div:first-child {
      display: flex;
      flex-direction: column;

      p {
        font-size: 1.125rem;
        margin: 5px 0;
      }

      .containerInput {
        margin-bottom: 10px;
      }

      h3 {
        font-size: 1.125rem;

        .dividedValue {
          color: #5f5f5f;
          font-weight: bold;
          font-size: 1.125rem;
        }

        .totalValue {
          color: #333333;
          font-weight: bold;
          margin-top: 106px;

        }
      }
    }
  }

  .mainInfoResumePayment {
    >div {
      border-bottom: 0.5px solid #5f5f5f;
      .editBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          color: #001537;
        }
        p {
          margin: 8px 0 0;
        }

      }
    }
  }

  .contractTerms {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 0;

    span {
      margin-top: 3px;
    }
  }

  .showCustomersResume {

    >div {

      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid #5f5f5f;
    }
  }

  .customersList {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
  }

  .showCustomers {
    @extend .customersList;
  }

  .hideCustomers {
    @extend .customersList;
    height: 0;
  }
}

.containerModal {
  margin: 16px;

  .containerTitle {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h1 {
      font-size: 1.75rem;
      color: #001537;
    }

    svg {
      font-size: 1.25rem;
    }
  }
}