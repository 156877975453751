#StoreItem {
    .container {
        position: relative;
        height: 200px;
        font-family: "Rainbow", sans-serif;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 15px;
        background-color: var(--primary);
        cursor: pointer;

        .descriptionContainer {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 200px;
            border-radius: 15px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            padding: 8px;

            .description {
                h2 {
                    font-size: 2.5rem;
                }
            }

            .logo {
                position: absolute;
                left: 16px;
                bottom: 16px;
                width: 100px;
                height: 100px;

                .image {
                    border-radius: 4px;
                    object-fit: cover;
                }
            }
        }
    }

    .simpleContainer {
        position: relative;
        height: 120px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom: 1px solid #0002;

        .descriptionContainer {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 16px;
            height: 100px;
            // color: #fff;
            display: flex;
            align-items: center;
            padding: 8px;

            .description {
                position: absolute;
                margin-left: 116px;
                top: 8px;
                // background-color: aqua;
                display: flex;
            }

            .logo {
                position: absolute;
                border-radius: 8px;
                width: 100px;
                height: 100px;

                .image {
                    border-radius: 4px;
                    object-fit: cover;
                }
            }
        }
    }
}