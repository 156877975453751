#Menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--secondaryBackground);

    .menu {
        background-color: var(--secondaryBackground);
        color: var(--text);
        padding: 16px;
        width: 250px;
        // border-right: var(--primary) 5px solid;
        
        ul {
            padding: 0;
            li {
                font-size: large;
                
                list-style-type: none;
                border-bottom: 2px solid #0002;
                padding: 8px 8px;
                transition: all 0.5s;
                opacity: 0.8;
                &:hover,
                :active {
                    border-bottom: 2px solid var(--primary);
                    opacity: 1;
                }
            }
        }
    }
    .menuFaq {
        display: flex;
        align-items: center;
        background-color: var(--secondaryBackground);
        color: var(--text);
        padding: 16px;
        width: 250px;
        ul {
            padding: 0;
            li {
                font-size: large;    
                list-style-type: none;
                padding: 8px 8px;
                transition: all 0.5s;
                opacity: 0.8;               
            }
        }
    }
    
}