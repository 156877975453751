#ScheduledItem {
    .container {
        border: var(--box-border) 1px solid;
        border-left: 8px var(--primary) solid;
        padding: 8px;
        border-radius: 8px;
        .top {
            display: flex;
            font-size: large;
            align-items: center;
            .icon {
                color: var(--primary);
                margin-right: 8px;
            }

            .date {
            }
        }
        .content {
            padding: 8px;
            opacity: 0.7;
        }
    }
}
