.label {
    color: var(--text);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    display: block;
    margin-bottom: 4px;
}

.required::after {
    content: '*';
    color: #ED0029;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    margin-left: 4px;
}