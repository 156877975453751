@import "styles/main.scss";

#CartView {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    max-width: 800px;
    margin: 0 auto;
    min-height: 100vh;

    .container {
        position: relative;
        background-color: var(--background);
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        min-height: 80vh;
    }

    .clearCartContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;

        button {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--text);

            p {
                all: unset;
                text-align: right;
                font-family: Roboto;
                font-size: 16px;
                line-height: 150%;
                position: relative;
                top: 1px;
            }
        }
    }

    .content {
        margin: 0;
        background-color: var(--secondaryBackground);
        padding: 0 32px;
        flex: 1;
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px;
        box-shadow: 0 10px 10px -10px rgba(0,0,0,0.2);
        z-index: 2;

        h1 {
            color: var(---text);
            font-family: Roboto;
            font-size: 22px;
            font-weight: 700;
            line-height: 150%;
        }

        button {
            all: unset;
            cursor: pointer;
        }
    }

    footer {
        width: 100%;
        box-shadow: 0 -10px 10px -10px rgba(0,0,0,0.2);
        padding: 12px 24px 24px;

        > span {
            color: var(--text);
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            line-height: 150%;
            display: block;
            margin-bottom: 12px;
            margin-left: 4px;
        }

        .submitButton {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 100%;
            border-radius: 8px;
            background: var(--secondary);

            color: #FFF;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            line-height: 150%;
        }

        .backButton {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 100%;
            margin-top: 8px;

            color: var(--primary);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
        }
    }
}

@media (max-width: 750px) {
    footer {
        > span {
            font-size: 20px !important;
        }
    }
}