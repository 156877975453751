@import "styles/main.scss";

#PaymentLink {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: #fff;

  @media (max-width: 620px) {
    height: auto;
}

  .content {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #6b6b6b;
    overflow: auto;
    
    @media (max-width: 740px) {
      justify-content: start;
    }

    .info {
      display: flex;
      height: 100%;
      justify-content: space-between;
      width: 495px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      >img:first-of-type {
        width: 120px;
        height: 118px;
        border-radius: 262px;
        background-color: var(--Neutral-Gray-500, #E0E0E0);
      }

      .name {
        color: var(--Primary-Primary-Dark, #001537);
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
      }

      .description {
        align-self: stretch;
        color: var(--Neutral-Gray-800, #5F5F5F);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
      }

      .value {
        color: var(--Text, #5F5F5F);
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
      }

      .paymentForm {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .paymentInformation {
          color: #fff;
          font-size: 14px;
        }

        >p {
          color: var(--Text, #5F5F5F);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          margin: 6px 0;
        }

        >div {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;

          >div {
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            align-self: stretch;
          }
        }

        .iconActive {
          border-radius: 8px;
          background: var(--Primary-Primary, #32008E);
          border: 1px solid var(--Primary-Primary, #32008E);
          &:hover {
            cursor: pointer;
          }
          >div {
            display: flex;
                flex-direction: column;
                align-items: center;
                grid-gap: 8px;
                gap: 8px;
                flex: 1 0;
                align-self: stretch;
            >p {
              margin: 0;
              color: var(--Neutral-White, #FFF);
              font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
        
        .iconInactive {
          border-radius: 8px;
          border: 1px solid var(--Neutral-Gray-500, #E0E0E0);
          background: #FFF;
          &:hover {
            cursor: pointer;
          }
          >div {
            display: flex;
                flex-direction: column;
                align-items: center;
                grid-gap: 8px;
                gap: 8px;
                flex: 1 0;
                align-self: stretch;
            >p {
              margin: 0;
              color: var(--Neutral-Gray-900, #333);
              font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }

        .pixIconActive {
          @extend .iconActive;
          >svg {
            margin: 7px;
          }
          g {
            fill: #ffffff;
          }
        }
        
        .pixIconInactive {
          @extend .iconInactive;
          >svg {
            margin: 8px;
          }
          g {
            fill: #955CFF;
          }
        }

        .creditIconActive {
          @extend .iconActive;
          path {
            fill: #ffffff;
          }
        }
        
        .creditIconInactive {
          @extend .iconInactive;
          path {
            fill: #955CFF;
          }
        }

        .ticketIconActive {
          @extend .iconActive;
          path {
            fill: #ffffff;
          }
        }
        
        .ticketIconInactive {
          @extend .iconInactive;
          path {
            fill: #955CFF;
          }
        }

        .disabled {
          pointer-events: none;
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .variableValue {
        display: flex;
        flex-direction: column;

        >label {
          min-width: 170px;
          justify-content: space-between;
          >span {
            color: red;
          }
        }
      }
    }

    .divisor {
      width: 0px;
      height: 90%;
      flex-shrink: 0;
      border-left: 1px solid #ddd;
    }
  }
}
