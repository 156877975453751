#ProductCompositionItem {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        animation: inAnimation 0.5s ease-in-out;
        margin-bottom: 4px;
        
        .start {
            display: flex;
            align-items: center;

            .description {
                text-align: left;
                color: var(--text);
                font-family: Roboto;
                font-size: 14px;
                line-height: 150%;
            
                b {
                    margin-left: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    // color: green;
                    opacity: 0.5;
                }
            }

            .quantitiy {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-right: 8px;
                border: 1px solid #BDBDBD;
                padding: 2px 6px;
                border-radius: 8px;
                background-color: white;
            }

            .selector {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-left: 0px;
                margin-right: 8px;
                top: -1px;
                position: relative;
            }

            .buttonIcons {
                color: var(--primary) !important;
                transition: opacity 0.3s;

                .icon {
                    position: relative;
                    top: 2px;
                    font-size: 16px;
                }
            }

            .disabledIcon {
                @extend .buttonIcons;
                opacity: 0.2;
                color: #BDBDBD !important;
                cursor: default;
            }
        }

        .end {
            .totalPriceContainer {
                min-width: 50px;
                display: flex;
                justify-content: flex-end; 
                // background-color: aqua;
                .totalPrice {
                    color: var(--primary);
                    font-size: small;
                    animation: inAnimationPrice 0.3s ease-in-out; 
                }
            }
            display: flex;
            align-items: center;

            
        }
    }
}

@keyframes inAnimationPrice {
    from {
        transform: translatex(-40px);
        opacity: 0;
    }
    to {
        transform: translatex(0px);
        opacity: 1;
    }
}

@keyframes inAnimation {
    from {
        transform: translatey(-100px);
        opacity: 0;
    }
    to {
        transform: translatex(0px);
        opacity: 1;
    }
}
