#Profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  background: #fff;

  > div:first-of-type {
    //PrivateHeader
    min-height: 50px;
  }

  .container {
    max-width: 700px;
    padding: 30px 60px;

    > h1 {
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
      margin-top: 30px;
    }

    > p {
      display: flex;
      align-items: center;
      color: var(--Text, #5f5f5f);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      gap: 8px;

      > svg {
        width: 24px;
        height: 24px;
        color: var(--Primary, #32008e);
      }
    }

    > button {
      display: flex;
      margin: 16px 0;
      height: 40px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      border-radius: 8px;
      border: 2px solid var(--Primary-Primary-Light, #955cff);

      > span:first-of-type {
        text-transform: capitalize;
        color: var(--Primary-Primary-Light, #955cff);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */

        > svg {
          margin-left: 8px;
          width: 24px;
          height: 24px;
        }
      }
    }

    .item {
      display: flex;
      min-height: 45px;
      flex-direction: column;
      gap: 4px;

      > span {
        color: var(--Neutral-Gray-900, #333);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      > p {
        display: flex;
        padding: 8px;
        margin: 0;
        align-items: center;
        align-self: stretch;

        border-radius: 4px;
        border: 1px solid var(--Neutral-Gray-600, #bdbdbd);
        background: var(--Neutral-Gray-400, #f0f0f0);

        color: var(--Neutral-Gray-800, #5f5f5f);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
      }
    }

    .listItem {
      display: flex;
      margin: 8px 0;
      padding: 8px;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;

      border-radius: 8px;
      border: 1px solid var(--Neutral-Gray-500, #e0e0e0);

      > div:last-of-type {
        display: flex;

        > button {
          padding: 6px;
          color: var(--Primary-Dark, #001537);
        }
      }
    }
  }
}
.modal {
  width: 100%;
  padding: 0 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  width: 60%;
  padding: 24px !important;
  min-height: auto !important;
  border-radius: 16px !important;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;

    > span {
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }

    svg {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      color: var(--Primary-Primary-Dark, #001537);
    }
  }

  > p {
    color: var(--Neutral-Gray-900, #333);
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 45px */
  }

  > button:first-of-type {
    display: flex;
    margin-top: 8px;
    height: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    background: var(--Primary-Primary-Light, #955cff);

    > span:first-of-type {
      color: var(--Neutral-White, #fff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  > button:first-of-type:hover {
    background-color: lighten(#955cff, 15%);
  }

  > button:last-of-type {
    display: flex;
    margin-top: 16px;
    height: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border: 2px solid var(--Primary-Primary-Light, #955cff);

    > span:first-of-type {
      color: var(--Primary-Primary-Light, #955cff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  > button:last-of-type:hover {
    background-color: #f3edff;
  }
}

.modalCardContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px !important;

  > div:nth-child(3) {
    padding: 0;
    > div {
      padding: 24px !important;
      border-radius: 16px !important;

      > form {
        > div:first-of-type {
          display: none;
        }

        > div:last-of-type > div:last-of-type {
          margin: 0px !important;
          display: flex !important;
          justify-content: space-between !important;
          align-items: center !important;
          width: 100% !important;
          padding: 8px !important;
          gap: 8px !important;
          
          > div {
            all: unset;
            display: flex !important;
            flex: 1;
          }
        }
      }
    }
  }
}

.modalAdrressContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  > div {
    border-radius: 16px !important;
    background-color: #FFF;
  }

  > div > div > div:last-of-type > form > div > div:last-of-type {
    flex-direction: row;
    > button {
      border-radius: 8px;
    }
  }
}
