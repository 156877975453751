#BoxProductComposition {
    margin-top: 24px;

    .container {
        margin-bottom: 16px;

        .description {
            color: var(--primaryDark);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 700;
            line-height: 150%;
            display: flex;
            justify-content: space-between;

            .descriptionText {
                margin-left: 8px;
                margin-bottom: 4px;
            }
        }

        .obrigatorio {
            color: #d00;
            font-size: 12px;
            transition: color 0.3s ease-in-out;
            margin-left: 4px;
            font-weight: 400;
            top: -1px;
            position: relative;
        }

        .obrigatorioChecked {
            @extend .obrigatorio;
            color: #666;
            font-size: 12px;
            text-align: right;
        }
    }
}

