#Extract {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  position: relative;
  background: #fff;

  > div:first-of-type {
    //PrivateHeader
    min-height: 50px;
  }

  .container {
    width: 80%;
    max-width: 900px;
    min-width: 300px;

    > h1 {
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
      margin-top: 30px;
    }

    .header {
      display: flex;
      margin: 16px 0;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      border-bottom: 2px solid var(--Neutral-Gray-500, #e0e0e0);
      padding-bottom: 12px;

      > div:first-of-type {
        > p {
          margin: 2px 0;
          color: var(--Neutral-Gray-900, #333);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
        }

        > p:first-of-type {
          font-size: 20px;

          > span {
            color: var(--Neutral-Gray-800, #5f5f5f);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }

        > p:last-of-type b {
          color: var(--Neutral-Gray-800, #5f5f5f);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 20.8px */
        }
      }

      > div:last-of-type {
        > p {
          margin: 0;
          font-family: Roboto;
          font-style: normal;
          line-height: 150%; /* 24px */
        }
        > p:first-of-type {
          color: var(--Neutral-Gray-800, #5f5f5f);
          font-size: 16px;
          font-weight: 400;
        }

        > p:last-of-type {
          color: var(--Feedback-Colors-Positive, #399c54);
          font-size: 32px;
          font-weight: 700;
        }
      }
    }

    .filter {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 24px;

      > div:first-of-type {
        display: flex;
        flex-direction: column;

        > p {
          margin: 4px 0;
          color: var(--Neutral-Gray-900, #333);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 27px */
        }

        > div {
          display: flex;
          gap: 16px;
          justify-content: space-evenly;

          > div {
            min-width: 73px;
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 50px;
          }

          .selected {
            background: var(--Primary-Primary-Dark, #001537);
            color: #fff;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 21px */
          }

          .unselected {
            cursor: pointer;
            border: 1px solid var(--Primary-Primary-800, #6c2ee0);
            color: var(--Neutral-Gray-900, #333);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            transition: background-color 0.3s ease, border-color 0.3s ease,
              color 0.3s ease;
          }

          .unselected:hover {
            background-color: rgba(
              108,
              46,
              224,
              0.1
            ); /* Fundo ligeiramente mais claro */
            border-color: var(
              --Primary-Primary-700,
              #5b27c9
            ); /* Borda mais escura */
            color: var(
              --Primary-Primary-800,
              #6c2ee0
            ); /* Cor do texto ligeiramente mais intensa */
          }
        }

        > div:first-of-type {
          display: flex;
        }
      }

      .input {
        display: flex;
        flex-direction: column;

        > span {
          color: var(--Neutral-Gray-900, #333);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 27px */
          margin-bottom: 6px;
        }
      }

      > div:last-of-type {
        display: flex;
        align-self: end;

        > button {
          height: 38px;
          border-radius: 8px;
          background: var(--Primary-Primary-Light, #955cff);
  
          > span:first-of-type {
            color: var(--Neutral-White, #fff);
            text-align: center;
  
            text-transform: none;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 21px */
          }
        }
  
        > button:hover {
          background-color: rgba(149, 92, 255, 0.85)
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 8px;
      align-self: stretch;
      margin-bottom: 30px;

      overflow: auto;
      max-height: 550px;

      > div {
        display: flex;
        width: 100%;
        flex-direction: column;

        padding-bottom: 12px;
        margin-bottom: 8px;
        border-bottom: 1px dashed var(--Neutral-Gray-500, #e0e0e0);

        > p {
          margin: 0;
        }

        > p {
          color: var(--Neutral-Gray-700, #a2a2a2);
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > span:first-of-type {
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 30px */
          }

          > span:last-of-type {
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 30px */
          }
        }
      }
    }

    > div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div:first-of-type {
        > span {
          color: var(--Neutral-Gray-900, #333);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          margin-right: 6px;
        }

        > div {
          height: 32px !important;
          margin-right: 6px;

          border-radius: 4px;
          border-color: var(--Neutral-Gray-600, #bdbdbd);
        }
      }
    }
  }
}
