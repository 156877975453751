#CouponInput {
    margin: 16px 0;
}

.loadingContainer {
    display: flex;
    background-color: #0002;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    justify-content: center;
    align-items: center;
}
.drawerContainer {
    width: 100vw;
    max-width: 600px;
    padding: 24px;
    align-self: center;
    gap: 8px;
    display: flex;
    flex-direction: column;
    background-color: var(--secondaryBackground);
    border-radius: 16px;

    h4 {
        all: unset;
        color: var(--text);
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        align-self: center;
    }

    form {
        display: flex;
        flex: 0;
        height: 80px;
        gap: 8px;
    }
    
    .alertMessage {
        color: #F44336;
        font-size: 12px;
    }
}

.couponButton {
    position: relative;
    display: flex !important;
    box-shadow: 2px 2px 7px -3px !important;
    border: 1px solid var(--primary) !important;
    padding: 8px !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 4px !important;
    gap: 8px;
    margin: 8px 0;
    display: flex !important;
    width: 100%;
    height: 100%;

    font-size: 15px;
    color: var(--text) !important;
    font-weight: 600;
}

.item {
    flex:1;
    position: relative;
    flex-direction: column;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.coupon {
    all: unset;
    color: var(--text);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 16px;
    display: block;
}

.modalContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 740px) {
    .coupon {
        font-size: 22px !important;
    }
}