#FindAddressPaymentLink {
    > div {
        >div {
            margin: 0px 0px 16px;
            > div {
               margin: 4px 0;
           }
        }
    }
    button {
        text-transform: none !important;
        height: 40px;
        font-size: 16px;
    }
    input:disabled {
        background-color: #F0F0F0;
    }
}