@import "styles/main.scss";

#Layout {
    max-width: 1920px;
    margin: auto;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;

    //box-shadow: 0px 0px 15px -5px #0005;
    .top {
        animation: headerIn 0.4s ease-in-out;

        .imageGramado {
            display: flex;
            justify-content: center;
            margin-bottom: 54px;


        }
    }

    .content {
        animation: contentIn 0.4s ease-in-out;
        // background-color: orchid;
        width: 100%;
        max-width: 1414px;
        margin: auto;
        background-color: var(--background);
    }

    .footer {
        bottom: 0;
        color: #5f5f5f;
        width: 100%;
        padding: 16px;
        border-top: 1px solid #BDBDBD;
        background-color: #F0F0F0;
        margin-top: 80px;

        .footerContainer {
            max-width: 1414px;
            margin: auto;

            ul {
                display: table;

                li {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 10px;
                }
            }
        }


        .title {
            font-weight: bold;
            font-size: 1rem;
            color: var(--text);
        }

        .header {
            margin-bottom: 16px;

            p {
                margin-top: 5px;
            }
        }

        .main {
            ul {
                padding: 0;

                li {

                    display: inline;
                }
            }

        }
    }

    .containerHelp {

        position: fixed;
        bottom: 32px;
        right: 153px;

        .cancelBox {
            display: flex;
            justify-content: flex-end;

            svg {
                color: #E0E0E0;
                width: 24px !important;
                height: 24px !important;
                cursor: pointer;
            }
        }

        .helpBox {
            display: flex;
            align-items: center;
            padding: 8px;

            width: 85px;
            height: 38px;
            background: rgba(0, 21, 55, 0.5);
            border-radius: 8px;


            >div {
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                    color: #FFF;
                    margin-right: 4px;
                }

                p {
                    color: #FFF;
                }
            }
        }




    }

    .containerFaqHelpFixed {
        position: fixed;
        bottom: 32px;
        right: 149px;


        .faqHelpFixed {
            background: #FFFFFF;
            box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            width: 375px;
            height: 552px;
            -ms-overflow-style: none;
            /* for Internet Explorer, Edge */
            scrollbar-width: none;
            /* for Firefox */
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
                /* for Chrome, Safari, and Opera */
            }
        }
    }
}

@keyframes headerIn {
    from {
        transform: translateY(-300px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes contentIn {
    from {
        // transform: translateY(300px);
        opacity: 0;
    }

    to {
        // transform: translateY(0px);
        opacity: 1;
    }
}

@media screen and (max-width: 600px) {

    .containerHelp {
        bottom: 17px !important;
        right: 14px !important;
    }

    .imageGramado {
        img {
            height: 85px !important;
            width: 220px;
        }

    }

}

@media screen and (max-width: 1100px) and (min-width: 600px) {}


@media screen and (max-width: 1366px) and (min-width: 1100px) {
    #Layout {

        max-width: 1366px;

        .content {
            max-width: 1060px;
        }

        .footerContainer {
            max-width: 1060px !important;

        }

    }


}