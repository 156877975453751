#ProductItem {
    position: relative;

    .container {
        position: relative;
        display: flex;
        flex-direction: row;
        height: 204px;
        // box-shadow: 2px 2px 10px -3px #0005;
        // border-radius: 8px;
        border: 1px solid #BDBDBD;
        border-radius: 16px;

        animation: fadeIn 0.5s ease-in-out;
        margin-bottom: 16px;

        .image {
            padding: 24px 32px 24px 24px;

            .img {
                border-radius: 7px;
                object-fit: cover;
                object-position: center;
                width: 253px;
                height: 156px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 24px 24px 24px 0;
            justify-content: space-between;

            .containerCard {
                display: flex;
                flex-direction: column;
                // color: #5f5f5f;
                color: var(--text);

                .headerCard {

                    display: flex;
                    justify-content: space-between;
                    align-items: center;


                    .price {
                        span {
                            display: flex;
                            justify-content: flex-end;
                            font-size: 0.625rem;
                            color: var(--text);
                        }

                        h4 {
                            font-weight: bold;
                            font-size: 1.125rem;
                            margin: 0;
                            color: #001537;
                        }
                    }

                    .name {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* number of lines to show */
                        -webkit-box-orient: vertical;
                        font-weight: bold;
                        font-size: 1.5rem;
                        color: #001537;
                    }
                }


                .description {
                    p {
                        font-size: 1rem;
                        text-align: justify;
                    }

                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    /* number of lines to show */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: var(--text);


                }
            }

            .footerCard {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .favorite {
                    display: flex;
                    align-items: center;

                    svg {
                        color: #FFB300;

                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                    }

                    img {

                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                    }

                    p {
                        font-size: 0.875rem;
                        color: var(--text);
                    }
                }


                .viewMore {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    svg {
                        color: #001537;

                        width: 12px;
                        height: 12px;
                    }

                    p {
                        font-size: 0.75rem;
                        color: #001537;
                    }
                }

            }

            .viewMoreOnly {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;

                svg {
                    color: #001537;

                    width: 12px;
                    height: 12px;
                }

                p {
                    font-size: 0.75rem;
                    color: #001537;
                }
            }


        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-60px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media screen and (max-width: 600px) {
    .container {
        margin: 16px;
        height: 171px !important;
        min-width: 340px;

        .image {
            padding: 12px 16px 12px 12px !important;

            .img {
                width: 148px !important;
                height: 147px !important;
            }
        }

        .content {
            padding: 12px 12px 12px 0px !important;

            .containerCard {

                .description {
                    p {
                        font-size: 0.75rem !important;
                    }
                }

                .headerCard {

                    flex-direction: column;
                    align-items: flex-start !important;

                    .price {
                        span {
                            justify-content: flex-start !important;

                        }


                    }

                    .name {
                        margin-bottom: 4px;
                        font-size: 1.125rem !important;
                    }
                }

            }

            .footerCard {

                .viewMore {
                    p {
                        font-size: 0.625rem !important;
                    }

                    svg {
                        width: 10px !important;
                        height: 10px !important;
                    }
                }

                .favorite {
                    p {
                        font-size: 0.625rem !important;
                    }

                    img {
                        width: 10px !important;
                        height: 10px !important;
                        margin-right: 4px !important;

                    }

                    svg {
                        width: 10px !important;
                        height: 10px !important;
                        margin-right: 4px !important;
                    }
                }



            }


        }
    }
}