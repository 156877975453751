@import "styles/main.scss";
#SocialMedias {
    .container {
        display: flex;
        .item {
            margin: 16px;
        }
        .icon{
            
        }
    }
}
