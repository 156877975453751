$closeHeight: 60px;
$openHeight: 200px;

#HomePage {
  min-height: 100vh;
  position: relative;

  >div:first-of-type { //PrivateHeader
    min-height: 50px;
  }

  .container {
    padding: 16px;
  }
}
