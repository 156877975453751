#Transfer {
  padding: 30px;
  width: 60%;
  min-width: 400px;
  h4 {
    all: unset;
    color: var(--text);
    font-size: 20px;
    font-weight: 700;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    > button {
      color: var(--primaryDark);
      width: 40px;
      height: 40px;
    }
  }

  .input {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-self: stretch;
    margin-bottom: 20px;

    > p {
      display: flex;
      align-items: center;
      margin: 0;
      color: var(--Neutral-Gray-900, #333);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;

      > span {
        margin: 0 4px;
        color: var(--Secondary-Secondary, #ed0029);
      }
      > svg {
        width: 18px;
        height: 18px;
      }
    }

    > div:first-of-type > div {
      display: flex;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(--Neutral-Gray-600, #bdbdbd);
      padding: 10px;

      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;

      > input {
        padding: 14px;
      }
    }
  }

  .transfer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    width: 100%;
    > p {
      margin: 0;
      color: var(--Neutral-Gray-700, #a2a2a2);
      text-align: center;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 30px */
    }
    > div:first-of-type {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      > div > input {
        text-align: center;
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 48px */
      }
    }
  }

  > button:first-of-type {
    margin-top: 24px;
    display: flex;
    height: 55px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--Primary-Primary-Light, #955cff);

    color: var(--Neutral-White, #fff);
    text-transform: capitalize;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }

  > button:last-of-type {
    margin-top: 24px;
    display: flex;
    height: 55px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 2px solid var(--Primary-Primary-Light, #955cff);

    color: var(--Primary-Primary-Light, #955cff);
    text-transform: capitalize;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }
}
