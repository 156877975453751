.container-auto-complete-google {
    position: relative;
    width: 100%;
}

.input-container-auto-complete-google {
    height: 60px;
}

.select-container-auto-complete-google {
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    background-color: var(--background);
    border-radius: 4px;
    border: 1px solid var(--primary);
    box-shadow: 2px 2px 10px #0005;
    z-index: 2;
}

.item-auto-complete-google {
    border-bottom: 1px solid #0004;
    padding: 8px;
    display: flex;
    align-items: center;
}

.content-auto-complete-google {}

.icon-auto-complete-google {
    margin: 8px;
}

.main-text-auto-complete-google {
    font-size: small;
}

.secondary-text-auto-complete-google {
    font-size: smaller;
    opacity: 0.6;
}