    #oldOrderList {
        padding: 0 16px;
    }
    
    .container-oldOrderList {
        border-left: #1ac 2px solid;
        padding-left: 8px;
        padding: 4px;
        margin-bottom: 16px;
    }
    
    .date-oldOrderList {
        color: #1ac
    }
    
    .orders-oldOrderList {}
    
    .order-oldOrderList {
        box-shadow: 2px 2px 5px 0px #0005;
        padding: 16px;
        border-radius: 8px;
        margin-top: 8px;
        font-size: small;
    }
    
    .top-order-oldOrderList {
        display: flex;
        justify-content: space-between;
    }
    
    .estabelecimento-order-oldOrderList {
        color: var(--primary  )
    }
    
    .date-order-oldOrderList {}
    
    .content-order-oldOrderList {}
    
    .orderItens-order-oldOrderList {
        font-size: x-small;
        padding: 16px;
        border: 1px solid #ddd;
        border-radius: 8px;
    }
    
    .orderItem-order-oldOrderList {
        display: flex;
        justify-content: space-between;
    }
    
    .text-orderItem-oldOrderList {}
    
    .value-order-oldOrderList {}
    
    .footer-order-oldOrderList {}
    
    .id-order-oldOrderList {}