#TicketForm {
  margin-top: 8px;
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
}

.addressContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  > div {
    padding: 24px !important;
    min-height: auto !important;
  }
}