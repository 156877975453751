.fadeTransition-enter {
    position: absolute;
    overflow: none;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    opacity: 0;
    /* transform: translateY(100vh); */
}
.fadeTransition-enter-active {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 1;
    /* transform: translateY(0vh); */
    transition: opacity 300ms ease-in;
}
.fadeTransition-exit {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    /* transform: translateY(0vh); */
    opacity: 1;
}
.fadeTransition-exit-active {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    /* transform: translateY(100vh); */
    transition: opacity 300ms ease-in;
}
