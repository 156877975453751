.container-item-address {
    border: 1px solid var(--primary  )!important;
    box-shadow: 2px 2px 5px #0005!important;
    width: 100%!important;
    margin: 8px 0!important;
    border-radius: 4px!important;
    display: flex!important;
    font-family: Roboto !important;
}

.icon-grid-item-address {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 8px;
}

.icon-item-address {
    color: var(--primary  );
}

.content-item-address {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 16px 0;
    margin-right: 40px;
}

.top-content-item-address {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.bottom-content-item-address {
    opacity: 0.7;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.actions-item-address {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}