
$primary: var(--primary);
$accent: var(--accent);
$text: var(--text);
$background: var(--secondaryBackground);
$box: var(--box);

$color-on-dark: var(--color-on-dark);
$color-on-light: var(--color-on-light);

$line-on-light: var(--line-on-light);
$border-on-light: var(--border-on-light);

$background-toolbar: var(--background-toolbar);
$background-menu: var(--background-menu);

$input-focus: $accent;
$input-unfocus: var(--input-unfocus);
$disabled: var(--disabled);

$cancelar: var(--cancelar);
$confirmar: var(--confirmar);


