#ProductItem {
    cursor: pointer;
    
    & + & {
        border-top: 1px dashed #E0E0E0;
    }

    .container {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 15px 8px;
        animation: fadeIn 0.5s ease-in-out;
        gap: 24px;
        align-items: center;
        
        .img {
            border-radius: 4px;
            object-fit: cover;
            object-position: center;
            width: 80px;
            height: 80px;
        }

        .content {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;

            .containerCard {
                display: flex;
                flex-direction: column;
                color: var(--text);
                justify-content: center;

                .name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;

                    h3 {
                        color: var(--primaryDark);
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 150%;
                    }
                }
                .description {
                    font-size: 0.625rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */

                    h3 {
                        -webkit-box-orient: vertical;
                        color: var(--text);
                        font-family: Roboto;
                        font-size: 12px;
                        line-height: 150%;
                    }
                }
            }

        }

        .price {
            display: flex;
            align-items: baseline;
            height: fit-content;

            span {
                color: var(--text);
                font-family: Roboto;
                font-size: 12px;
                font-weight: 700;
                line-height: 150%;
                margin-right: 4px;
            }

            h3 {
                color: var(--text);
                font-family: Roboto;
                font-size: 18px;
                font-weight: 700;
                line-height: 150%;
                
            }
        }
    }
}

.mobilePrice {
    display: none;
    align-items: baseline;
    height: fit-content;
    margin-top: 4px;

    span {
        color: var(--text);
        font-family: Roboto;
        font-size: 12px;
        font-weight: 700;
        line-height: 150%;
        margin-right: 4px;
    }

    h3 {
        color: var(--text);
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        
    }
}

.imageFallback {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background-color: #dfdcdc;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        color: #fbfbfb;
        font-size: 40px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-60px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}


@media (max-width: 600px) {
    .price {
        display: none !important;
    }

    .mobilePrice {
        display: flex;
    }
}