body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Poppins", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    color: var(--text);
    scroll-behavior: smooth;

    > iframe {
        display: none;
    }
}

html {
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h2 {
    font-weight: 400;
    margin: 16px;
    // color: var(--primary);
}

@font-face {
    font-family: "Rainbow";
    src: url("./assets/fonts/Rainbow/Rainbow Paper.ttf") format("truetype");
    font-weight: bold;
}