@import "styles/main.scss";
#PaymentGuestPage {
    .logo {
        display: flex;
        padding: 16px;
        align-items: center;
        justify-content: center;
        img{
            object-fit: contain;
            height: 30px;
        }
    }
    .container {
        .installmentContainer{
            margin-bottom: 16px;
        }
    }
}
