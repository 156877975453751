#StoresPage {
    .container {
        padding-top: 8px;
        .storesList {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            column-gap: 16px;
            row-gap: 8px;
            flex-direction: row;
            justify-content: center;
        }
    }
}
