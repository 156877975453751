#TourPage {
  .storesTour {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    column-gap: 16px;
    row-gap: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
  }

}

.modalMessage {
  padding: 16px;
  text-align: center;

  b {
    color: var(--primary);
  }
}

@media screen and (min-width: 1367px) {
  #TourPage {

    .storesTour {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    }
  }


}