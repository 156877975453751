#AboutProject {
  padding: 0 16px;
  z-index: 2;

  .container {
    p {
      font-size: 1rem;
      color: var(--text);
      text-align: justify;
    }

    .header {
      >div {
        width: 50%;
      }

      .text {}

      .aboutprojectImg {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 352px;
          // height: 262px;
          width: 100%;
          object-position: center;
          border-radius: 8px;

        }
      }
    }

    .bottom {

      >div {
        width: 50%;
      }

      .aboutprojectVideo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px 0 0;

        iframe {
          border-radius: 8px;
        }
      }
    }

    >div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      >div {
        padding: 8px;
      }
    }
  }
}



@media screen and (max-width: 600px) {


  #AboutProject {
    p {
      font-size: 0.875rem !important;
    }

    .container {
      >div {

        display: flex;
        flex-direction: column;

      }

      .header {
        >div {
          width: 100% !important;
        }


        display: flex;
        flex-direction: column-reverse !important;

        .aboutprojectImg {
          img {

            width: 357px !important;
            height: 240.92px !important;
          }

        }
      }

      .bottom {
        >div {
          width: 100% !important;
        }


        .aboutprojectVideo {

          iframe {

            width: 357px !important;
            height: 240.92px !important;
          }


        }
      }

    }
  }



}

@media screen and (max-width: 1100px) {


  #AboutProject {
    .container {

      .header {
        .aboutprojectImg {

          img {
            height: 262px;
          }
        }

        >div {
          width: 100% !important;
        }
      }

      .bottom {
        .aboutprojectVideo {

          iframe {
            height: 262px;
          }
        }

        >div {
          width: 100% !important;
        }

      }

    }

  }



}