.underlineClass {
    &::after {
        padding: 32px;
    }
}
.textField {
    input,
    textarea {
        padding-left: 8px;
        color: var(--text) !important;
    }
    input[type="date"],
    input[type="time"] {
        color: var(--text);
    }
    input[type="time"]::-webkit-calendar-picker-indicator,
    input[type="date"]::-webkit-calendar-picker-indicator {
        cursor: pointer;
        margin-right: 2px;
        display: none;
        opacity: 0.6;
        transition: opacity 250ms;
    }

    input[type="time"]::-webkit-calendar-picker-indicator:hover,
    input[type="date"]::-webkit-calendar-picker-indicator:hover {
        opacity: 1;
    }
}
.MuiSelect {
    padding-left: 8px !important;
    color: var(--text) !important;
}
