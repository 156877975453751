$closeHeight: 60px;
$openHeight: 200px;

#ProductsPage {
    .container {
        position: relative;
        .header {
            position: relative;
            animation: headerIn 0.4s ease-in-out;
        }
        .content {
            animation: contentIn 0.4s ease-in-out;
            position: relative;
            border-radius: 16px;
            overflow: visible;
            transition: all 0.3s ease-in-out;
            background-color: var(--background);
            margin: 0 16px;
            
            display: flex;
            flex-direction: column;

            h2{
                padding: 8px 8px;
            }
        }
    }
}

.headerContainer {
    animation: contentIn 0.4s ease-in-out;
    position: relative;
    border-radius: 16px;
    overflow: visible;
    transition: all 0.3s ease-in-out;
    max-width: 960px;
    margin: 50px auto 0;
}

.filterContainer {
    animation: contentIn 0.4s ease-in-out;
    position: relative;
    transition: all 0.3s ease-in-out;
    max-width: 960px;
    margin: 24px auto 0;
}

.productsContainer {
    animation: contentIn 0.4s ease-in-out;
    position: relative;
    border-radius: 16px;
    overflow: visible;
    transition: all 0.3s ease-in-out;
    max-width: 960px;
    margin: 24px auto 0;

    padding: 0;
    padding-bottom: 80px;
}

.filter {
    margin: 0 16px;
    border-radius: 4px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    background: white;
    gap: 8px;
    margin-bottom: 24px;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.2);

    .icon {
        color: #BDBDBD;
    }

    input {
        flex: 1;
        height: 38px;
        border: 0;

        color: #888;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        letter-spacing: 0.15px;
        
        &::placeholder {
            color: #BDBDBD;
        }
    }
}

@keyframes headerIn {
    from {
        transform: translateY(-300px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes contentIn {
    from {
        transform: translateY(300px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}
