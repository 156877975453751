#PaymentResumePage {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px;
  h2 {
    font-size: 1.125rem;
    color: #001537;
    font-weight: 700;
  }
  h3 {
    color: #5f5f5f;
    font-size: 1.125rem;
    font-weight: 700;
  }
  .headerInfoResumePayment {

    .headerTitle {
      display: flex;
    }

    .resumeDown {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;

      svg {
        font-size: 2rem;
        color: #001537;
        margin-right: 20px;
      }
    }
    .containerInfoResumePayment {
      border-bottom: 0.5px solid #5f5f5f;

      div {
        margin: 16px 0;
      }

      h4 {
        color: var(--primary);
        font-size: 1.125rem;
        font-weight: 700;
        margin: 5px 0 0;
      }

      p {
        color: #5f5f5f;
        margin: 0;
      }
    }
  }
  .footerInfoResumePayment {
    div:first-child {
      display: flex;
      flex-direction: column;
      p {
        font-size: 1.125rem;
        margin: 5px 0;
      }
      .containerInput {
        margin-bottom: 10px;
      }
      h3 {
        font-size: 1.125rem;

        .dividedValue {
          color: #5f5f5f;
          font-weight: bold;
        }
        .totalValue {
          color: #399c54;
          font-weight: bold;
        }
      }
    }
  }

  .mainInfoResumePayment {
    div {
      border-bottom: 0.5px solid #5f5f5f;
    }
  }
  .contractTerms {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 0;
    span {
      margin-top: 3px;
    }
  }
  .showCustomersResume {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #5f5f5f;
  }

  .customersList {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
  }
  .showCustomers {
    @extend .customersList;
  }
  .hideCustomers {
    @extend .customersList;
    height: 0;
  }
}

.containerModal {
  margin: 16px;
  .containerTitle {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    h1 {
      font-size: 1.75rem;
      color: #001537;
    }
    svg {
      font-size: 1.25rem;
    }
  }  
  
}
