.form {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.inputContainer {
    display: flex;
    flex: 1;
    margin: 0 4px;
    margin-bottom: 8px;
    flex-direction: column;
    // align-items: center;
    justify-content: flex-end;
    // background-color: aqua;
}
.inputRow {
    display: flex;
    flex: 1;
    margin-bottom: 16px;
    flex-direction: row;
    flex-wrap: wrap;
}
.buttonContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}
