#FaqPage {
    .footerButtonContact {

        padding: 5px 16px 16px;
        p {
            color: var(--text);
            font-size: 0.875rem;
        }
        button {
            font-weight: bold;
        }

    }

    .titleHeader {
        position: fixed;
        z-index: 2;
        width: 375px;
        padding: 0 16px;
        display: flex;
        border-radius: 16px 16px 0 0;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        background-color: #FFF;

        p {
            color: #001537;
            font-weight: bold;
        }

        svg {
            color: #001537;

        }
    }

    .mainFaq {
        padding: 50px 16px 0;


        .containerCategory {
            .containerQuestion {
                margin: 16px 0;
                padding: 0 16px;
                border: 1px solid #E0E0E0;
                box-sizing: border-box;
                border-radius: 16px;

                .containerDescription {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .description {
                        font-weight: 500;
                        color: #333333;
                    }
                }

                .answers {
                    font-size: 0.875rem;
                    color: #5f5f5f;
                }

            }
        }
    }
}

@media screen and (max-width: 600px) {

    .titleHeader {
        width: 100% !important;
    }

}