.marker {
    position: relative;
    img {
        position: absolute;
        top: -60px;
        left: -30px;
        width: 60px;
        height: 60px;
        object-fit: contain;
    }
}