.submitButton {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    background-color: var(--secondary);
    
    color: white;
    font-weight: 700;
    line-height: 150%;
    font-size: 18px;
}

.info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-top: 32px;
    margin-bottom: 12px;
     
    > svg {
        margin-right: 8px;
    }
}