@import "styles/main.scss";
#PreviewPhotosProfile {
    margin-bottom: 24px;

    h2 {
        padding: 0 16px;
        // background-color: aqua;
    }
    .container {
        // padding: 16px;
        .thumbnail {
            // background-color: #1ac;
            // width: 100px;
            padding: 8px;
            .imgContainer {
                background-color: #ddd;
                border-radius: 16px;
                img {
                    width: 100%;
                    border-radius: 16px;
                }
            }
        }
    }
}
.photo {
    margin: auto;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
    // border-radius: 16px;

    img {
        background-color: #ddd;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
