.card-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 170px;
  max-width: 290px;
  margin: 16px auto;
  background: #32008E;
  border-radius: 18px;
  color: #fff;
  font-size: smaller;
  text-shadow: 0.8px 0.8px 0px  #000;
}

.content-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
}

.number-cvv-card {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
}

.date-name-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.date-card {
  text-align: left;
}

.name-card {
  text-align: left;
}

.logo-card {
  position: absolute;
  border-radius: 16px;
  width: 80px;
  top: 20px;
  left: 20px;
}
