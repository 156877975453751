#InstallmentSelect{
    .container {
        div {
            div {
                div {
                    padding-right: 0;
                }
            }
        }
    }
}