@import "styles/main.scss";
#CartBar {
    .container {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0px;
        right: 0px;
        padding: 16px;
        height: 50px;
        // border-radius: 16px 16px 0px 0px;
        justify-content: space-between;
        background-color: var(--primary);
        color: var(--onPrimary);
        align-items: center;
    }
    .visible {
        @extend .container;
        animation: inAnimation 0.5s ease-in-out;
    }
    .inVisible {
        @extend .container;
        animation: outAnimation 0.5s ease-in-out;
        background-color: cadetblue;
    }
}

@keyframes inAnimation {
    from {
        transform: translatey(50px);
        opacity: 0;
    }
    to {
        transform: translatey(0px);
        opacity: 1;
    }
}
@keyframes outAnimation {
    from {
        transform: translatey(0px);
        opacity: 1;
    }
    to {
        transform: translatey(50px);
        opacity: 0;
    }
}

.cartContainer {
    all: unset;
    cursor: pointer;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    max-width: 600px;
    margin-left: calc(50% - 300px);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0 16px;
    box-sizing: border-box;
    
    position: fixed;
    bottom: 0;

    > div {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .icon {
        font-size: 22px;
        color: white;
    }

    .title {
        color: white;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        line-height: 50%;
    }
    
    .price {
        color: white;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        line-height: 50%;
    }

    .badge {
        background-color: white;
        color: var(--primaryDark);
        height: 14px;
        width: 14px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        span {
            font-size: 12px;
            font-weight: bold;
            font-family: Roboto;
        }
    }
}

@media (max-width: 620px) {
    .cartContainer {
        margin-left: 0;
    }
}