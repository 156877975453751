#Pix {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;



  >button {
    width: 340px;
    display: flex;
    justify-content: center;

    align-self: center;
    margin-top: 8px;

    color: var(--Neutral-White, #fff);
    background-color: var(--primary, #fff);
    text-transform: capitalize;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 24px */
  }



  .title {
    align-self: stretch;
    color: var(--Primary-Primary-Dark, #001537);
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .description {
    padding: 0 4px;
    display: flex;
    height: 64px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;

    color: var(--Neutral-Gray-800, #5f5f5f);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;

    >b {
      display: contents;
    }
  }

  .pixContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img {
      margin-top: 30px;
    }
    >img {
      width: 163px;
      height: 163px;
      flex-shrink: 0;
      background-color: var(--Neutral-Gray-500, #e0e0e0);
    }

    >div:first-of-type {
      margin-top: 10px;
      display: flex;
      align-items: center;
      grid-gap: 10px;
      justify-content: space-between;
      gap: 10px;
    }

    .divisor {
      min-height: 12px;
      border-left: 1px solid #ddd;
    }
  }

  .pixFooter {
    width: 100%;
    height: 100%;
    overflow: auto;

    @media (max-width: 620px) {
      overflow: hidden;
    }

    .code {
      width: 100%;
    }

    >p {
      display: block;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--Neutral-Gray-500, #e0e0e0);
      background: var(--Neutral-Gray-400, #f0f0f0);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    >div {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      .copyCode {
        cursor: pointer;
        display: flex;
        width: 340px;
        height: 40px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--Primary-Primary, #32008e);

        color: var(--Neutral-White, #fff);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        /* 24px */
      }

      .waitingMessage {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 340px;
        height: 40px;
        padding: 8px 16px;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid var(--Neutral-Gray-500, #e0e0e0);
      }
    }



    .disabled {
      cursor: not-allowed !important;
      opacity: 0.6;
    }
  }
}

.approved {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;

  >p {
    margin: 8px 0;
  }

  >p:first-of-type {
    color: var(--Feedback-Colors-Positive, #399c54);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  >p:last-of-type {
    color: var(--Text, #5f5f5f);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .whatsAppContact {
    margin-top: 30px;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    gap: 16px;
    border: 1px solid #399C54;
    background-color: #399C541A;
    font-family: Roboto;
    font-size: 18px;
    line-height: 30px;

    a {
      color: #955CFF;
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }

    >div {
      >p {
        padding: 0;
        margin: 0;
      }
    }
  }
}