#ProfileHeader {
    .container {
        // background-color: aquamarine;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        .backgroundContainer {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 300px;
            // background-color: aqua;
            background-image: url("../../../../../res/images/social/background-header.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            
            @media only screen and (max-width: 800px) {
                background-image: url("../../../../../res/images/social/background-header.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                height: 250px;
            }
        }
        .name {
        }
        .info {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            h3 {
                margin: 16px;
            }
            .icon {
                font-size: 8px;
            }
        }
        .actions {
            display: flex;
            flex: 1;
            width: 100%;
            max-width: 300px;
            // background-color: turquoise;
            .actionButton {
                margin: 4px;
                border-radius: 50px;
            }
        }

        .data {
            display: flex;
            .item {
                margin: 8px 16px;
                .value {
                }
                .label {
                }
            }
        }
    }
}
