#Pix {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  width: 60%;
  min-width: 400px;

  border-radius: 16px;

  .title {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;

    > span:first-of-type {
      align-self: center;
      color: var(--Primary-Primary-Dark, #001537);
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
    }

    > button {
      padding: 6px;
      > span:first-of-type > svg {
        color: var(--primaryDark);
        width: 40px;
        height: 40px;
      }
    }
  }

  .description {
    padding: 0 4px;
    display: flex;
    height: 64px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;

    color: var(--Neutral-Gray-800, #5f5f5f);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;

    > b {
      display: contents;
    }
  }

  .pixContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      width: 163px;
      height: 163px;
      flex-shrink: 0;
      background-color: var(--Neutral-Gray-500, #e0e0e0);
    }

    > div:first-of-type {
      margin-top: 10px;
      display: flex;
      align-items: center;
      grid-gap: 10px;
      justify-content: space-between;
      gap: 10px;
    }

    .divisor {
      min-height: 12px;
      border-left: 1px solid #ddd;
    }
  }

  .pixFooter {
    width: 100%;
    overflow: hidden;
    margin-top: 16px;

    .code {
      width: 100%;
    }

    > p {
      display: block;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--Neutral-Gray-500, #e0e0e0);
      background: var(--Neutral-Gray-400, #f0f0f0);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > button:first-of-type {
      display: flex;
      align-items: center;
      margin-top: 30px;
      height: 50px;
      flex-direction: column;
      border-radius: 8px;
      background: var(--Primary-Primary-Light, #955cff);

      > span:first-of-type {
        color: var(--Neutral-White, #fff);
        text-transform: capitalize;
        text-align: center;
        font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 1px;

        > svg {
          margin-right: 4px;
        }
      }
    }

    > button:first-of-type:hover {
      background-color: lighten(#955cff, 15%);
    }

    > button:last-of-type {
      display: flex;
      margin-top: 16px;
      height: 50px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      border-radius: 8px;
      border: 2px solid var(--Primary-Primary-Light, #955cff);

      > span:first-of-type {
        color: var(--Primary-Primary-Light, #955cff);
        text-transform: capitalize;
        text-align: center;
        font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }

    > div > button {
      width: 340px;
      align-self: center;
      margin-top: 8px;

      color: var(--Neutral-White, #fff);
      background-color: var(--primary, #fff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
    }

    .disabled {
      cursor: not-allowed !important;
      opacity: 0.6;
    }
  }
}

.approved {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    margin: 8px 0;
  }

  > p:first-of-type {
    color: var(--Feedback-Colors-Positive, #399c54);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
  }

  > p:last-of-type {
    color: var(--Text, #5f5f5f);
    text-align: center;
    font-family: Ubuntu;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
}

.timer {
  display: flex;
  > span:first-of-type {
    margin-right: 4px;
  }

  > b:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;

    > div {
      display: flex;
      align-items: center;
      text-align: center;
    }

    span {
      color: #5f5f5f;
      font-weight: bold;
    }

    > span {
      margin: 0 0.1rem;
    }
  }
}
.modal {
  width: 100%;
  padding: 0 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  width: 60%;
  padding: 24px !important;
  min-height: auto !important;
  border-radius: 16px !important;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;

    > span {
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }

    svg {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      color: var(--Primary-Primary-Dark, #001537);
    }
  }

  > p {
    color: var(--Neutral-Gray-900, #333);
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 45px */
  }

  > button:first-of-type {
    display: flex;
    margin-top: 8px;
    height: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    background: var(--Primary-Primary-Light, #955cff);

    > span:first-of-type {
      color: var(--Neutral-White, #fff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  > button:first-of-type:hover {
    background-color: lighten(#955cff, 15%);
  }

  > button:last-of-type {
    display: flex;
    margin-top: 16px;
    height: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border: 2px solid var(--Primary-Primary-Light, #955cff);

    > span:first-of-type {
      color: var(--Primary-Primary-Light, #955cff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  > button:last-of-type:hover {
    background-color: #f3edff;
  }
}
