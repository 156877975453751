#TimeSelect {
    .lastVacancies {


        display: flex;
        align-items: center;
        color: #a00;
        padding: 4px 0;

        p {
            margin-left: 5px;
        }

    }
}