#ContainerDescription {

    margin: 16px 0;
    padding: 0 16px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 16px;

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            color: #001537 !important;
            font-size: 1rem;
            font-weight: bold;
        }
        svg {
            color: #001537;
        }
    }

    .moreDescription {
        margin-bottom: 8px;
        text-align: justify;
    }


    .cardDatails {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        overflow: auto;

    }

    .showCard {
        @extend .cardDatails;
    }

    .hideCard {
        @extend .cardDatails;
        height: 0;
    }


}