#CreditForm {
  margin-top: 8px;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    .address {
      > button {
        display: none !important;
      }

      > div:first-of-type {
        cursor: pointer;

        button {
          padding: 0 !important;
        }
      }

      .textField {
        pointer-events: none;
        > div:first-of-type {
          padding-right: 7px;
        }
      }
    }
    .birthDay input {
      padding: 18.5px 14px;
    }
    .inputError fieldset {
      border-color: #f44336;
    }
    .error {
      font-size: 0.75rem;
      color: #f44336;
    }
  }
}
.addressContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  > div {
    padding: 24px !important;
    min-height: auto !important;
  }
}
