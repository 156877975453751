.tabContainer {
    margin: 4px 0 8px !important;
    >div {
        >div {
            gap: 8px;
        }
    }

    > div:nth-of-type(3) {
        > div {
            gap: 8px !important;
        }
    }
}

@media (max-width: 600px) {
    .tabContainer {
        margin: 4px 24px 8px !important;
    }
}

.tab {
    border-radius: 4px !important;
    border: 1px solid #BDBDBD !important;
    min-height: 32px !important;
    height: 32px !important;
    padding: 0 16px !important;
    max-width: 286px !important;

    color: #BDBDBD !important;
    text-align: center !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 150% !important;
    text-transform: capitalize !important;
    
    &.Mui-selected {
        background-color: var(--secondary) !important;
        border: 0 !important;
        color: #FFF !important;
    }
}

.MuiTabs-scrollButtons {
    height: 32px !important;
}

.MuiTabs-indicator {
    display: none !important;
}

.MuiTabScrollButton-root.Mui-disabled {
    width: 24px;
}