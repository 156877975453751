#MoreDetails {
  .container {

    .containerDescription {
      margin: 16px 0;
      padding: 0 16px;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 16px;

      >div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        ;

        span {
          font-size: 0.875rem;
          color: #001537;
          font-weight: bold;


        }

      }

      .description {
        font-weight: 500;
        color: #333333;
        margin-bottom: 32px;
      }
    }

    .answers {
      font-size: 0.875rem;
      color: #5f5f5f;
    }

    .cardDatails {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: auto;
    }

    .showCard {
      @extend .cardDatails;
    }

    .hideCard {
      @extend .cardDatails;
      height: 0;
    }


    p {
      color: var(--text);
      font-size: 0.875rem;
      flex-direction: column;
    }



    .description {
      text-align: justify;
      margin-bottom: 32px;


      p {
        font-size: 1rem;

      }

      .showDescription {}

      .hideDescription {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* number of lines to show */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--text);
      }

    }

    .textViewMore {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        font-size: 0.875rem;
        color: #001537;

      }
    }

    .nameTour {
      margin-bottom: 16px;

      h3 {
        font-size: 1.5rem;
        font-weight: bold;
        color: #001537;
      }

    }

    .tourLocation {
      display: flex;
      align-items: center;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 16px;

      h3 {
        font-size: 1.5rem;
        font-weight: bold;
        color: #001537;
      }

      .favorite {
        display: flex;
        align-items: center;

        svg {
          color: #FFB300;

          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        img {

          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        p {
          font-size: 0.875rem;
          color: #5f5f5f;
        }
      }

    }

  }

  .containerButton {

    button {
      width: 250px;
      height: 40px;
      font-weight: bold;
    }


  }


}



.carousel {
  margin-bottom: 32px;
}

@media screen and (max-width: 600px) {


  #MoreDetails {
    margin: 0 16px;

    .containerButton {
      button {
        width: 100% !important;
      }
    }

    .description {
      p {
        font-size: 0.875rem !important;
      }
    }

    .tourLocation {
      flex-direction: column;
      align-items: flex-start !important;

      h3 {
        font-size: 1.2rem !important;
        font-weight: bold;
        color: #001537;
      }

      .favorite {

        svg {
          width: 12px !important;
          height: 12px !important;
          margin-right: 10px;
        }

        img {
          width: 12px !important;
          height: 12px !important;
          margin-right: 10px;
        }

        p {
          font-size: 0.625rem;
        }
      }
    }
  }



}