#cart {
    .barContainer {
        display: flex;
        flex: 1;
        background-color: var(--primary);
        color: #fff;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
        border-radius: 16px 16px 0 0;
        height: 50px;
        .quantity {
        }
        .title {
            display: flex;
            flex: 1;
            justify-content: center;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
        }
        .price {
        }
        transition: all 0.5s ease-in-out;
        transform: translateY(0px);
    }

    .barVisible {
        @extend .barContainer;
        opacity: 1;
        transform: translateY(0px);
    }
    .barHide {
        @extend .barContainer;
        //opacity: 0;
        transform: translateY(80px);
    }
}
.drawerContainer {
    background-color: var(--background);
    .header {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 8px;
        align-items: center;
        h2 {
            position: absolute;
            margin: auto;
            text-align: center;
            right: 100px;
            left: 100px;
        }
    }
    .content {
        margin: 8px;
        .cartTourItem {
            position: relative;
            padding: 8px;
            box-shadow: 2px 2px 10px -5px #0005;
            border-radius: 16px;
            display: flex;
            flex-direction: row;
            .removeButton {
                position: absolute;
                top: 4px;
                right: 4px;
            }
            .image {
                display: flex;
                background-color: #0005;
                width: 90px;
                height: 90px;
                border-radius: 16px;
                margin-right: 8px;
                img {
                    width: 90px;
                    height: 90px;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .info {
                display: flex;
                flex-direction: column;
                flex: 1;
                .tourName {
                }
                .dateTime {
                }
                .persons {
                }
                .price {
                    color: green;
                }
            }
        }
    }
    .footer {
        padding: 16px 8px;
        .totals {
            display: flex;
            justify-content: space-between;
            font-size: 1.5rem;
            margin-bottom: 16px;
        }
        .buttons {
            .sendButton {
                background-color: var(--primary);
                color: #fff;
                font-size: 1rem;
                padding: 8px;
                border-radius: 16px;
                justify-content: center;
                display: flex;
            }
        }
    }
}
