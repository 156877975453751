@import "./main.scss";
html,
body {
    padding: 0;
    margin: 0;
    font-size: 14px;
    background-color: $background;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Poppins, Oxygen, Ubuntu, Cantarell, Fira Sans,
        Droid Sans, Helvetica Neue, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    outline: none;
}

h1 {
    font-weight: 400;
    margin: 0;
}

h2 {
    font-weight: 400;
    margin: 0;
}

h3 {
    font-weight: 400;
    margin: 0;
}

// .MuiInput-underline {
//     &::after {
//         border-color: $accent !important;
//     }
// }

// .MuiPickersToolbar-toolbar {
//     background-color: $accent !important;
// }

// .MuiPickersDay-daySelected {
//     background-color: $accent !important;
//     color: colorOn($accent) !important;
// }

// // .MuiPickersDay-current {
// //     color: $primary !important;
// // }

// .MuiButton-textPrimary {
//     color: $accent !important;
// }

// .MuiTypography-colorPrimary {
//     color: $accent !important;
// }
