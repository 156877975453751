@import "styles/main.scss";
#ProfilePage {
    .container {
        margin: auto;
        max-width: 1024px;
        box-shadow: 0px 0px 2px #0005;
        border-radius: 8px;
        .buttonContainer {
            margin: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 16px;
        }
        .button {
            max-width: 400px;
            border-radius: 50px;
        }
    }
}
