.container {
    background-color: antiquewhite;
    padding: 16px;
    .item {
        // font-size: large;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .label {}
        .value {}
    }
}