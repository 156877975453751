
.modalContainer {
  background-color: var(--secondaryBackground);
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
      all: unset;
      color: var(--text);
      font-size: 20px;
      font-weight: 700;
      display: flex;
      margin-bottom: 16px;
      align-items: center;
      justify-content: space-between;
      > button {
        color: var(--primaryDark);
        width: 40px;
        height: 40px;
      }
  }
}

.modalSubmitButton {
  all: unset;
  cursor: pointer;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--secondary);
  border: 2px solid var(--secondary);

  color: white;
  font-weight: 500;
  font-family: Roboto;

  &:disabled {
    background-color: #BDBDBD;
    border-color: #BDBDBD;
  }
}

.modalCancelButton {
  all: unset;
  cursor: pointer;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #FFF;
  border: 2px solid var(--secondary);

  color: var(--secondary);
  font-weight: 500;
  font-family: Roboto;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 10px;
}