.img{
    transition: all 0.2s;
}
.hideImage{
    @extend .img;
    opacity: 0;
}
.showImage{
    @extend .img;
    opacity: 1;
}