@import "styles/main.scss";
#ProfilePicture {
    .container {
        border-radius: 200px;
        width: 140px;
        height: 140px;
        background-color: #ddd;
        box-shadow: 0 0 20px -10px;
        
        img {
            border-radius: 200px;
            width: 140px;
            height: 140px;
            object-fit: cover;
            background-color: #fff;
        }
    }
}
