#FormTourPage {
  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 18px;

    h2 {
      font-weight: bold;
      font-size: 1.5rem;
      color: #001537;

    }

    h4 {
      font-size: 0.875rem;
      color: #333333;
    }

    .infoTourSchedule {
      background: #e6f1ff;
      border-radius: 10.875px;
      margin: 16px 0px 0px;
      padding: 12px;

      p:first-child {
        font-size: 0.875rem;

        span {
          font-weight: bold;
          color: #32008e;
        }
      }

      p:last-child {
        font-size: 0.75rem;
      }
    }

    .tourSchedule {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.938rem;
      height: 32px;
      margin-bottom: 21px;
      background: #ed0029;
      border-radius: 12px;

      p {
        font-size: 0.75rem;
        color: #fff;
      }
    }

    .addCustomers {
      .line {
        border-bottom: 1px solid #333333;
      }

      margin-bottom: 24px;

      .containerIcons {
        background-color: #f2f8ff;

        div:last-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px;

          div button {
            background-color: #fff;
            border-radius: 50%;

            span svg {
              color: #001537;
              font-size: 0.75rem;
            }
          }

          .addIcons {
            display: flex;

            >p {
              padding: 0 7px;
            }
          }
        }

        h2 {
          font-size: 1rem;
          color: #001537;
          display: flex;
          align-items: center;
        }
      }

      p {
        font-size: 0.75rem;
        color: #5f5f5f;
      }
    }

    .inputs {
      .addNewPeople {

        button {

          width: 250px;
          height: 40px;
          font-weight: bold;
        }

        svg {
          margin-left: 10px;
        }
      }

      .containerInfoCustomer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.6rem;
      }

      >div {
        margin: 15px 0;
      }

      .inputsHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;

        svg {
          color: #5f5f5f;
        }
      }
    }


    .containerTotal {

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 30px;
      border-top: 1px solid #E5E5E5;





      .containerButton {
        width: 250px;

        button {
          height: 40px;
          font-weight: bold;
        }
      }

      .infoSchedule {
        p {
          display: flex;
          align-items: center;
          margin: 5px 0;

          b {
            margin-left: 5px;
            font-size: 1.125rem;
            color: #001537;
          }
        }

      }


    }
  }
}

.carousel {
  margin-bottom: 32px;
}

@media screen and (max-width: 600px) {

  .containerInfoCustomer {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .addNewPeople {
    button {

      width: 100% !important;
    }

  }

  .containerTotal {
    flex-direction: column-reverse;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    border-top: none !important;

    .containerButton {
      width: 100% !important;
      margin-top: 16px;
    }
  }

}