#Footer {
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 16px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >div {
    margin: 12px 0;
  }

  .icons {
    display: flex;
    gap: 8px;
  }
}