#Home {
  display: flex;
  flex-direction: column;
  padding: 60px 120px;

  .header {
    display: flex;
    align-items: center;
    gap: 40px;
    flex: 1;

    img {
      width: 170px;
      height: 170px;
      flex-shrink: 0;
      border-radius: 262px;
      background-color: var(--Neutral-Gray-500, #e0e0e0);
      object-fit: contain;
    }

    > div {
      display: flex;
      flex-direction: column;
      flex: 1;

      > div:first-of-type {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div:first-of-type {
          color: var(--Primary-Primary-Dark, #001537);
          font-family: Roboto;
          font-size: 35px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 52.5px */
        }

        > div:last-of-type {
          display: flex;
          align-items: center;
          gap: 8px;

          > svg:hover {
            cursor: pointer;
          }
        }
      }

      > div:last-of-type {
        display: flex;
        align-items: center;
        gap: 8px;
        text-transform: capitalize;
      }
    }
  }

  .description {
    color: var(--Text, #5f5f5f);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 30px */
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    > p {
      align-self: stretch;
      color: var(--Text, #5f5f5f);
      text-align: center;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 16px;

      > div {
        display: flex;
        width: 350px;
        height: 121px;
        padding: 16px;
        align-items: center;
        gap: 0px;
        border-radius: 16px;
        /* Shadows/Small */
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        > span {
          color: var(--Neutral-White, #fff);
          font-family: Roboto;
          font-size: 19px;
          font-style: normal;
          font-weight: 700;
        }

        > svg:last-of-type {
          color: #fff;
          width: 24px;
          height: 24px;
        }

        transition: background-color 0.3s ease;
      }

      > div:first-of-type {
        background: var(--Primary-Primary-Dark, #001537);
      }
      > div:first-of-type:hover {
        background-color: lighten(#001537, 20%);
      }
      
      > div:last-of-type {
        background: var(--Primary-Primary-900, #4d13b7);
      }
      > div:last-of-type:hover {
        background-color: lighten(#4d13b7, 20%);
      }
    }

    > span {
      color: var(--Primary-Primary-Light, #955cff);
      text-align: center;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
.modal {
  width: 100%;
  padding: 0 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  padding: 24px !important;
  min-height: auto !important;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;

    > span {
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }

    svg {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      color: var(--Primary-Primary-Dark, #001537);
    }
  }

  > div:last-of-type {
    overflow: auto;
    min-height: 200px;
    max-height: 200px;
    > p {
      margin: 12px 0;
      align-self: stretch;
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    > span {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: start;

      color: var(--Primary-Primary-Light, #955cff);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;

      cursor: pointer;

      > svg {
        width: 30px;
        height: 30px;
      }
    }

    > h5 {
      margin: 8px 0;
      align-self: stretch;
      color: var(--Neutral-Gray-800, #5f5f5f);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }
  }

  > button {
    display: flex;
    margin-top: 8px;
    height: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    background: var(--Primary-Primary-Light, #955cff);

    > span:first-of-type {
      color: var(--Neutral-White, #fff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  > button:hover {
    background-color: lighten(#955cff, 15%);
  }
}
