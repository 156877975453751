@import "styles/main.scss";
#InteressesProfile {
    margin-bottom: 24px;

    h2 {
        margin: 0 16px;
    }
    .container {
        display: flex;
        justify-content: space-between;
        padding: 16px;
    }
}
