#Credit {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .infoCredit {
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: var(--primary-200);
    border: 1px solid #A16EFF;
    border-radius: 8px;
    padding: 8px 16px;
    margin-bottom: 12px;

    >svg {
      color: var(--primary);
    }
    >div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: var(--text);

    }
  }

  .title {
    align-self: stretch;
    color: var(--Primary-Primary-Dark, #001537);
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 370px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;
    .installmentContainer {
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }
  > button {
    width: 340px;
    align-self: center;
    margin-top: 8px;
    
    color: var(--Neutral-White, #fff);
    text-transform: capitalize;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }
  .disabled {
    cursor: not-allowed !important;
    opacity: 0.6;
  }
}
.approved {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;

  >p {
    margin: 8px 0;
  }

  > p:first-of-type {
    color: var(--Feedback-Colors-Positive, #399c54);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
  }

  > p:last-of-type {
    color: var(--Text, #5f5f5f);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  
  .whatsAppContact {
    margin-top: 30px;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    gap: 16px;
    border: 1px solid #399C54;
    background-color: #399C541A;
    font-family: Roboto;
    font-size: 18px;
    line-height: 30px;

    a {
      color: #955CFF;
      text-decoration: underline;
      &:hover {
        opacity: 0.8;
      }
    }

    > div {
      > p {
        padding: 0;
        margin: 0;
      }
    }
  }
}
