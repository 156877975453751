#ProductDetail {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    max-width: 800px;
    margin: 0 auto;
    min-height: 100vh;

    .backButton {
        all: unset;
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1;
        
        .backButtonIcon {
            color: #888;
            font-size: 26px;
            font-weight: bold;
        }
    }

    .imageContainer {
        width: 100%;
        height: 300px;
        border-radius: 8px;
        object-fit: cover;
        margin-right: 24px;
    }
    
    .imageFallback {
        width: 100%;
        height: 300px;
        border-radius: 4px;
        background-color: #dfdcdc;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
    
        .icon {
            color: #fbfbfb;
            font-size: 40px;
        }
    }

    .container {
        position: relative;
        background-color: var(--background);
        margin: 0 16px;
        width: 100%;
        padding: 56px 32px 24px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        min-height: 80vh;

        .content {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            padding: 0 32px 24px;
            box-shadow: 0 10px 10px -10px rgba(0,0,0,0.2);
            z-index: 9;
            margin-left: -32px;
            margin-right: -32px;
            width: calc(100% + 64px);
        }

        .name {
            color: var(--primary);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 800;
            line-height: 150%;
            display: block;
        }

        .description {
            color: var(--text);
            font-family: Roboto;
            font-size: 14px;
            line-height: 150%;
        }

        .obsLabel {
            color: var(--primaryDark);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            display: block;
            margin-bottom: 4px;
            margin-left: 4px;
            margin-top: 24px;
        }

        .obsInput {
            background-color: white;
            border: 0;
            border-radius: 8px;
            resize: none;
            width: 100%;
            padding: 8px;
            font-family: Roboto;
            font-size: 16px;
            color: #888;
        }

        .productComposition {
            background-color: var(--secondaryBackground);
            margin-left: -32px;
            margin-right: -32px;
            width: calc(100% + 64px);
            padding: 0 32px 32px;
            flex: 1;
        }
    }

    footer {
        display: flex;
        margin-top: auto;
        gap: 20px;
        align-items: center;
        margin-top: auto;
        background-color: var(--background);
        width: calc(100% + 60px);
        box-shadow: 0 -10px 10px -10px rgba(0,0,0,0.2);
        padding: 16px 24px 0;
        margin-left: -30px;
        margin-right: -30px;
        justify-content: space-between;

        .priceContainer {
            span {
                color: var(--text);
                font-family: Roboto;
                font-size: 30px;
                font-weight: 700;
                line-height: 150%;
            }
        }

        .quantityContainer {
            border-radius: 8px;
            border: 1px solid #BDBDBD;
            padding: 0 8px;
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 40px;

            .iconButton {
                all: unset;
                cursor: pointer;
                padding-top: 2px;
                color: var(--primary);

                span {
                    font-size: 16px;
                }

                &:disabled {
                    color: #BDBDBD;
                    cursor: default;
                }
            }

            .quantityText {
                color: var(--text);
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
            }
        }

        .submitButton {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            padding: 0 24px;
            background-color: var(--primary);
            border-radius: 8px;
            gap: 40px;



            p, span {
                color: #FFF;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 700;
                line-height: 150%;
            }

            .icon {
                color: #FFF;
                font-size: 18px;
                margin-left: 8px;
            }

            &:disabled {
                background-color: #BDBDBD;
            }
        }
    }
}

.mobilePrice {
    color: var(--text);
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    margin-top: 16px;
}

@media (max-width: 730px) {
    .mobilePrice {
        display: block;
    }

    .priceContainer {
        display: none;
    }

    .container {
        max-width: 400px;
        margin: 0 !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .imageContainer {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 16px;
    }

    .submitButton {
        flex: 1;
    }

    #ProductDetail {
        padding: 0;
    }
}