#AlertApp {
    .container {
        position: relative;
        background-color: var(--box);
        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: center;
        a{
            text-decoration: underline;
        }
        .iconApp {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
        }
        .content {
            display: flex;
            flex: 1;
            flex-direction: column;
        }
        .closeIconContainer {
            position: absolute;
            top: 8px;
            right: 8px;
        }
        .closeIcon {
        }
    }
}
.paper {
    max-width: 600px;
    width: 100vw;
    margin: auto;
}
