#Tag {
  padding: 30px;
  width: 60%;
  min-width: 400px;
  h4 {
    all: unset;
    color: var(--text);
    font-size: 20px;
    font-weight: 700;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    > button {
      color: var(--primaryDark);
      width: 40px;
      height: 40px;
    }
  }

  > button:first-of-type {
    display: flex;
    height: 55px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    > span > svg {
      margin-left: 8px;
      width: 24px;
      height: 24px;
    }

    text-transform: capitalize;
  }

  .disableAdd {
    border: 2px solid var(--Neutral-Gray-700, #a2a2a2);
    color: var(--Neutral-Gray-700, #a2a2a2);
  }

  .enableAdd {
    border: 2px solid var(--Primary-Primary-Light, #955cff);
    color: var(--Primary-Primary-Light, #955cff);
  }

  .tagContainer {
    margin-top: 30px;
    display: flex;
    height: 158px;
    padding: 16px;
    align-items: center;
    justify-content: space-between;

    border-radius: 16px;
    border: 1px solid var(--Primary-Primary-Light, #955cff);
    background: var(--Primary-Primary-200, #f3edff);

    > div:first-of-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > p {
        margin: 0;
        color: var(--Neutral-Gray-900, #333);
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */

        > b {
          text-transform: uppercase;
          color: var(--Neutral-Gray-900, #333);
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
        }
      }

      > span {
        display: flex;
        align-self: flex-start;
        align-items: center;
        gap: 4px;

        cursor: pointer;

        color: var(--Primary-Primary-Light, #955cff);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-decoration-line: underline;
      }
    }
  }

  .emptyTag {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    > p {
      color: var(--Neutral-Gray-800, #5f5f5f);
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }
  .input {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-self: stretch;
    height: 100px;

    > p {
      display: flex;
      align-items: center;
      margin: 0;
      color: var(--Neutral-Gray-900, #333);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;

      > span {
        margin: 0 4px;
        color: var(--Secondary-Secondary, #ed0029);
      }
      > svg {
        width: 18px;
        height: 18px;
      }
    }

    > div:first-of-type > div {
      display: flex;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(--Neutral-Gray-600, #bdbdbd);

      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;

      > input {
        padding: 14px;
      }
    }
  }
  .buttonsContainer {
    > button:first-of-type {
      margin-top: 36px;
      display: flex;
      height: 55px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      border-radius: 8px;
      background: var(--Primary-Primary-Light, #955cff);

      color: var(--Neutral-White, #fff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }

    > button:last-of-type {
      margin-top: 12px;
      display: flex;
      height: 55px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      border-radius: 8px;
      border: 2px solid var(--Primary-Primary-Light, #955cff);

      color: var(--Primary-Primary-Light, #955cff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }
  }
}
.newTagModal {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  > div {
    border-radius: 16px !important;
    background-color: #fff;
  }
}
.modal {
  width: 100%;
  padding: 0 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  width: 60%;
  padding: 24px !important;
  min-height: auto !important;
  border-radius: 16px !important;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;

    > span {
      color: var(--Cinza-0, #333);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }

    svg {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      color: var(--Primary-Primary-Dark, #001537);
    }
  }

  > p {
    margin: 0;
    color: var(--Neutral-Gray-900, #333);
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 45px */
  }

  > p:last-of-type {
    font-size: 22px;
    margin: 12px 0;
  }

  > button:first-of-type {
    display: flex;
    margin-top: 8px;
    height: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    background: var(--Primary-Primary-Light, #955cff);

    > span:first-of-type {
      color: var(--Neutral-White, #fff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  > button:first-of-type:hover {
    background-color: lighten(#955cff, 15%);
  }

  > button:last-of-type {
    display: flex;
    margin-top: 16px;
    height: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border: 2px solid var(--Primary-Primary-Light, #955cff);

    > span:first-of-type {
      color: var(--Primary-Primary-Light, #955cff);
      text-transform: capitalize;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  > button:last-of-type:hover {
    background-color: #f3edff;
  }
}
