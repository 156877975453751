:root {
    --primary: #32008e;
    --on-primary: #ffffff;
    --primary-dark: #001537;
    --primary-light: #955cff;
    --primary-200: #F3EDFF;
    --primary-300: #dfcdff;
    --primary-500: #B68EFF;
    --primary-900: #4d13b7;
    --secundary-dark: #57000f;
    --secondary: #955cff;
    --secondary-light: #f3edff;
    --on-secondary: #ffffff;
    --secondary-dark: #001537;
    --tertiary: #ff00a6;
    --tertiary-light: #ffd9f5;
    --tertiary-lighter: #fecdd2;
    --quaternary: #ff1744;
    --alert: #f44336;
    --positive: #399c54;
    --positive: #399c54;
    --aprove: #399c54;
    --dimiss: #ff1744;
    --banner: #ffb300;
    --cancel: #333333;
    --text-dark: #333333;
    --text: #5f5f5f;
    --placeholder: #bdbdbd;
    --inactive: #bdbdbd;
    --disable: #e0e0e0;
    --border: #dfe0eb;
    --low-light: #e0e0e0;
    --default-color: #001537;

    --on-primary: #fff;
    --on-secondary: #fff;
    --on-tertiary: #fff;

    --box-border: #c5c5c5;

    --box-primary: #e6f1ff;
    --box-secondary: #f2f8ff;

    --box-shadow: #c5c5c5;

    --background: #fff;
    --disabled: #ddd;
    --sucess: #219653;
    --warning: #ffb300;
    --erro: #ed0029;
    --h1: 2rem;
    --h2: 1.6rem;
    --h3: 1.4rem;
    --h4: 1.2rem;
}
